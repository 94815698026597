// Polnisch

module.exports = {
  'main_navigation': {
    'login': 'Zaloguj się',
    'registration': 'Rejestracja',
    'courses': 'Moje szkolenia',
    'coursesDetail': 'Szczegóły szkolenia',
    'catalog': 'Katalog',
    'learningProfile': 'Twój profil',
    'contact': 'Kontakt',
    'profile': 'Profil',
    'pwLost': 'Nie pamiętasz hasła?',
    'about': 'Nota prawna',
    'terms_and_conditions': 'Warunki użytkowania',
    'logout': 'Wyloguj się',
    'faq': 'FAQ',
    'trainingmanager': 'Wnioski',
    'trainingmanagerrequest': 'Zapytanie',
    'trainingmanagermanage': 'Zarządzaj',
    'trainingmanagermanagedetail': 'Szczegóły',
    'noResult': 'Nie znaleziono żadnych wyników.'
  },
  'general': {
    'offline': {
      'headline': 'Jesteś offline!',
      'text': 'Ta aplikacja wymaga aktywnego połączenia internetowego do prawidłowego działania.',
    },
    'year': 'Rok',
    'month': 'Miesiąc',
    'noResult': 'Nie znaleziono żadnych wyników.',
    'months': {
      '10': 'Październik',
      '11': 'Listopad',
      '12': 'Grudzień',
      '01': 'Styczeń',
      '02': 'Luty',
      '03': 'Marzec',
      '04': 'Kwiecień',
      '05': 'Maj',
      '06': 'Czerwiec',
      '07': 'Lipiec',
      '08': 'Sierpień',
      '09': 'Wrzesień'
    },
    'category': 'Kategoria',
    'calendar': 'Kalendarz',
    'search': 'Wyszukiwanie',
    "searchError": "Wprowadź co najmniej trzy znaki.",
    'showMore': 'Pokaż więcej',
    'showLess': 'Pokaż mniej',
    'pagination': {
      'next': 'Następny',
      'prev': 'Poprzedni',
      'separator': 'z',
      'pageSize': 'Pozycje na stronie'
    }
  },
  'maintenance': {
    'title': 'Prace konserwacyjne',
    'text': 'Ze względu na prace konserwacyjne strona internetowa Akademii jest aktualnie niedostępna. Spróbuj ponownie później.'
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'Are you sure you want to cancel this booking?'
    }
  },
  'pages': {
    'notFound': {
      'headline': 'Przykro nam!',
      'text1': 'Żądana strona nie została znaleziona.',
      'text2': 'Skorzystaj z przycisku Cofnij w swojej przeglądarce lub przejdź do strony głównej.',
      'button': 'Powrót do strony głównej'
    },
    'courseCalendar': {
      'expired': 'Nieważne',
      'soonExpired': 'Wkrótce utraci ważność',
      'expiredMeetings': 'Poprzednie daty'
    },
    'profile': {
      'userdata': 'Dane użytkownika',
      'password': 'Hasło',
      'genderLab': 'Tytuł',
      'gender': {
        'men': 'Pan',
        'woman': 'Pani',
        'diverse': '-',
        'unknown': 'Brak danych'
      },
      'firstname': 'Imię',
      'lastname': 'Nazwisko',
      'email': 'E-mail',
      'strasse': 'Ulica / Nr.',
      'city': 'Kod pocztowy / Miejscowość',
      'phone': 'Telefon',
      'fax': 'Faks',
      'position': 'Pozycja',
      'department': 'Dział',
      'required': 'Pole obowiązkowe',
      'oldPassword': 'Stare hasło',
      'newPassword': 'Nowe hasło',
      'newPasswordRep': 'Powtórzenie',
      'company': 'Przedsiębiorstwo',
      'language': 'Język'
    },
    'catalog': {
      'back': 'Cofnij',
      'bookingDialog': {
        'notice': 'Prosimy podać w tym miejscu powód zapytania'
      }
    },
    'contact': {
      'info': 'Prosimy o wpisanie treści zapytania w okienku wiadomości. W przypadku zainteresowania konkretnym szkoleniem, prosimy o odpowiednią informację: W ten sposób Twoja wiadomość będzie mogła być wysłana bezpośrednio do osoby odpowiedzialnej.',
      'trainingSelection': 'Wybór szkolenia',
      'yourMessage': 'Twoja wiadomość',
      'success': 'Wiadomość została pomyślnie wysłana!',
      'otherReason': 'Ogólna prośba o kontakt',
      'otherReasonSubject': 'Inne zapytanie',
      'chooseReason': 'Wybierz'
    },
    'learningProfile': {
      'title': 'Twój profil',
      'unit': 'Bloki tematyczne',
      'points': 'Całkowita liczba punktów',
      'history': 'Przebieg nauki',
      'restoreFilter': 'Resetuj wszystkie filtry',
      'certificate': 'Certyfikat',
      'downloadAll': 'Pobierz całość',
      'withoutCategory': 'Brak kategorii',
      'tooltip01': 'Ukończyłeś/-aś',
      'tooltip02': 'z',
      'tooltip03': 'bloków tematycznych.',
      'gamification': {
        'badge':{
          'title': 'Nagrody',
          'congratulations': 'Gratulacje!',
          'achieved': 'U hebt een nieuwe onderscheiding gekregen!'
        }
      }
    },
    'courseDetail': {
      'title': 'Szczegóły szkolenia',
      'back': 'Powrót do widoku listy',
      'progress': 'Postęp',
      'expiry': 'ważny do',
      'classroomNo': 'Numer szkolenia',
      'contactPerson': 'Osoba kontaktowa',
      'targetGroup': 'Grupa docelowa',
      'bonusPoints': 'Punkty bonusowe',
      'classroomContent': 'Treści szkolenia',
      'webinarLight': 'Informacje dot. webinaru',
      'specials': 'Cechy szczególne',
      'stornoConditions': 'Warunki anulowania rezerwacji',
      'meeting': 'Terminu',
      'meetings': 'Terminy',
      'freeDay': 'Free day',
      'freeDayInfo': 'Dni wolne od pracy nie są wymienione powyżej',
      'speaker': 'Trener / referent',
      'location': 'Miejsce szkolenia',
      'requested': 'WYSŁANO ZAPYTANIE',
      'waitlist': 'LISTA REZERWOWA',
      'fullyBooked': 'BRAK WOLNYCH MIEJSC',
      'edit': 'Edytuj',
      'webinarSpeaker': 'Referent',
      'docsAndLinks': 'Dokumenty i linki',
      'links': 'Linki',
      'webinar': {
        'host': 'Otwórz webinar',
        'state': {
          'ended': 'Webinar zakończył się',
          'beforeStart': 'Webinar rozpocznie się '
        }
      },
      'sidebar': {
        'price': 'Cena',
        'duration': 'Czas nauki',
        'to': 'do',
        'numberOfParticipants': 'Liczba uczestników',
        'status': 'Status rezerwacji',
        'totalStatus': 'Stan ogólny',
        'contentStatus': 'Status szkolenia'
      },
      'bookingInformation': {
        'title': 'Informacja dot. rezerwacji',
        'note': 'Adnotacja',
        'accounts': 'Miejsce powstawania kosztów',
        'invoiceRecipient': 'Odbiorca rachunku',
        'invoiceAddress': 'Adres rachunku',
        'food': 'Preferencje żywieniowe',
        'arrival': 'Przyjazd',
        'arrivalType': {
          'none': 'Brak danych',
          'car': 'samochodem',
          'train': 'pociągiem',
          'plane': 'samolotem'
        },
        'arrivalDate': 'Data przyjazdu',
        'departure': 'Wyjazdu',
        'departureDate': 'Data wyjazdu',
        'flightNumber': 'Numer lotu',
        'trainNumber': 'Numer pociągu',
        'railwayStation': 'Dworzec',
        'placeholder': {
          'departure_airport': 'Lotnisko odlotu',
          'destination_airport': 'Docelowy port lotniczy',
          'departure_trainstation': 'Stacja odjazdowa',
          'destination_trainstation': 'Stacja docelowa',
          'departure_car': 'Miejsce odjazdu',
          'destination_car': 'Miejsce docelowe',
          'from': 'z',
          'to': 'do'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          'Styczeń',
          'Luty',
          'Marzec',
          'Kwiecień',
          'Maj',
          'Czerwiec',
          'Lipiec',
          'Sierpień',
          'Wrzesień',
          'Październik',
          'Listopad',
          'Grudzień'
        ]
      },
      'reserve': {
        'title': 'Nie jesteś dostępny/-a w podanym/-ych terminie/-ach?',
        'text': 'Zgłoś swoje zainteresowanie! Zostaniesz wtedy poinformowany/-a o innych możliwych terminach.',
        'button': 'Zgłoś zainteresowanie',
        'prebooked': {
          'text': 'Zgłosiłeś/-aś swoje zainteresowanie tym szkoleniem.'
        }
      },
      'downloads': {
        'modalHeadline': 'Pliki do pobrania',
        'sidebarLink': 'Dokument(y)',
      }
    },
    'course': {
      'type': {
        '0': 'Szkolenie online',
        '1': 'Szkolenie online',
        '2': 'Szkolenie online',
        '3': 'Szkolenie online',
        '4': 'Szkolenie online',
        '5': 'Szkolenie online',
        '6': 'Szkolenie stacjonarne',
        '7': 'Webinar',
        '-2': 'Projekt',
        'undefined': '-'
      },
      'status': {
        'open': 'Otwarty',
        'sent': 'Sent',
        'passed': 'Zaliczono',
        'sent': 'Wysłane',
        'failed': 'Nie zaliczono',
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        'expired': 'Nieważne',
        'booked': 'Zarezerwowano',
        'started': 'Trwa przetwarzanie',
        'upcoming': 'Wkrótce dostępny',
        'running': 'Trwa',
        'completed': 'Zakończony'
      },
      'notification': {
        'success1': 'Szkolenie zostało pomyślnie ',
        'success2': ' zakończone!'
      },
      'warning': {
        'headline': 'Uwaga!',
        'body': {
          '1': 'Wybrałeś inny język niż ostatnio dla tego szkolenia.',
          '2': 'Jeśli przejdziesz dalej i klikniesz "Rozpocznij szkolenie", postęp zostanie zresetowany.',
        },
        'buttons': {
          'start': 'Rozpocznij szkolenie',
          'startPrevious': 'Wybierz poprzedni język',
        }
      },
      'statusLabel': {
        'training': 'Szkolenie',
        'content': 'Zawartość',
        'attendance': 'Attendance',
        'exam': 'Egzamin',
        'survey': 'Ankieta'
      },
      'start': {
        'survey': 'Rozpocznij ankietę',
        'exam': 'Rozpocznij egzamin'
      },
      'prefix': {
        'category': 'Kategoria',
        'state': 'Status',
        'type': 'Rodzaj szkolenia'
      },
      'noAssigned': 'Nie posiadasz przypisanych szkoleń.',
      'noSolved': 'Nie ukończyłeś jeszcze żadnego szkolenia. Rozpocznij teraz naukę, wybierając swój pierwszy blok tematyczny w zakładce „moje szkolenia”!',
      'noAssignedBookable': 'Nie jesteś przypisany/-a do żadnych dostępnych szkoleń.',
      'noResult': 'Nie znaleziono szkoleń spełniających kryteria wyszukiwania.',
      'loadingFailed': 'Wystąpił nieznany błąd podczas przesyłania żądanego zestawu danych.',
      'noResultInCategory': 'Ta kategoria nie posiada jeszcze przypisanych szkoleń.',
      'meeting': {
        'noMeeting': 'brak terminu',
        'multipleMeetings': 'różne Terminy'
      },
      'project': {
        'courses': 'Szkolenia'
      }
    },
    'login': {
      'invalidUser': 'Invalid login data. Username / Password is wrong!',
      'invalidPassword': 'Invalid login data. You have #0 more attempts to enter the password correctly.',
      'accountBlocked': 'Your account has been blocked, please contact a responsible employee.',
      'error': 'Nazwa użytkownika i/lub hasło są nieprawidłowe!',
      'empty': 'Prosimy wypełnić wszystkie pola.',
      'back': 'Powrót do logowania',
      'description': 'W celu ponownego wysłania danych dostępu, prosimy o podanie nazwy użytkownika oraz adresu e-mail.',
      'password-reset': {
        'description': 'Prosimy wybrać nowe hasło dla tego konta.',
        'success': 'Twoje hasło zostało pomyślnie zmienione.',
        'error': 'Podane hasła nie są identyczne.'
      },
      'placeholder': ' Nazwa użytkownika / adres e-mail',
      'button': 'Poproś o dane dostępu',
      'success': 'Zapytanie zostało pomyślnie wysłane.',
      'errorForgot': 'Nie znaleziono użytkownika spełniającego podane kryteria.',
      'errorForgotSecurityQuestion': 'Odpowiedź jest niestety niepoprawna!'
    },
    'registration': {
      'description': 'Jeśli nie posiadasz jeszcze danych do logowania, wystarczy, że zarejestrujesz się wykorzystując kod promocyjny Twojej firmy. Krótko po tym otrzymasz wiadomość e-mail.',
      'actionCodeError': 'Podany link aktywacyjny jest nieprawidłowy',
      'actionCodeLimitExceeded': 'Wykorzystano maksymalną liczbę możliwych rejestracji dla podanego kodu promocyjnego.',
      'registerDescription': 'Prosimy wypełnić poniższy formularz. Dane dostępu zostaną wysłane w ciągu kilku minut na Twój adres mailowy.',
      'title': 'Rejestracja',
      'required': 'Pole obowiązkowe*',
      'send': 'Wyślij',
      'completeMessage': 'Ukończyłeś/-aś pomyślnie rejestrację.'
    },
    'dataProtection': {
      'accept': 'Zgadzam się',
      'acceptText': 'Tak, akceptuję niniejsze warunki użytkowania'
    },
    'securityQuestion': {
      'title': 'Pytanie bezpieczeństwa',
      'chooseQuestion': 'Wybierz',
      'questionSelection': 'Prosimy wybrać pytanie',
      'yourAnswer': 'Prosimy o wprowadzenie odpowiedzi',
      'description': 'Prosimy o odpowiedź na pytanie bezpieczeństwa oraz wybór nowego hasła do konta.'
    },
    'faq': {
      'title': 'Często stawiane pytania'
    }
  },
  'sidebar': {
    'all': 'Wszystkie',
    'categories': 'KATEGORIE',
    'status': 'STATUS',
    'courseType': 'RODZAJ SZKOLENIA',
    'topics': 'ZAKRES TEMATYCZNY'
  },
  'placeholder': {
    'username': 'Nazwa użytkownika',
    'password': 'Hasło',
    'actionCode': 'Kod promocyjny'
  },
  'button': {
    'login': 'LOGOWANIE',
    'login-sso': 'LOGOWANIE ZA POMOCĄ SINGLE-SIGN-ON',
    'send': 'WYŚLIJ',
    'start_course': 'START',
    'changePassword': 'Zmień hasło',
    'reset': 'Resetuj',
    'save': 'Zapisz',
    'request': 'Zapytanie',
    'storno': 'Anuluj',
    'canceled': 'Termin odwołany',
    'close': 'Zamknij',
    'booking': 'Zarezerwuj',
    'waitlist': 'Lista rezerwowa',
    'start_webinar': 'Rozpocznij webinar',
    'open_webinar': 'Otwórz webinar',
    'cancel': 'Anuluj',
    'confirm': 'Potwierdź'
  },
  'bookingState': {
    '0': 'WYSŁANO ZAPYTANIE',
    '1': 'ZAREZERWOWANO',
    '2': 'LISTA REZERWOWA',
    '3': 'ANULOWANO',
    '4': 'ANULOWANO',
    '5': 'ODRZUCONO',
    '6': 'ZGŁOSZONO',
    'onlineTraining': {
      '0': 'WYSŁANO ZAPYTANIE',
      '1': 'ZAREZERWOWANO',
      '2': 'ODRZUCONO'
    }
  },
  'foodPreference': {
    'none': 'Brak',
    'lactoseIntolerance': 'Nietolerancja laktozy',
    'vegetarian': 'Dieta wegetariańska',
    'vegan': 'Dieta wegańska',
    'nutAllergy': 'Uczulenie na orzechy',
    'diabetic': 'Cukrzyca',
    'glutenFree': 'Dieta bezglutenowa',
    'kosher': 'Dieta koszerna',
    'halal': 'Dieta halal',
    'other': 'Inne'
  },
  'notifications': {
    'error': 'Niestety coś się nie udało.',
    'errors': {
      'missingInput': 'Prosimy wypełnić wszystkie pola',
      'imageLoad': 'Nie można załadować wybranego obrazu'
    },
    'success': {
      'book': {
        'meeting': 'Zarezerwowano pomyślnie miejsce na szkolenie stacjonarne.',
        'webinar': 'Zarezerwowano pomyślnie miejsce na webinar.',
        'course': 'Zarezerwowano pomyślnie miejsce na szkolenie.',
        'project': 'Zarezerwowano pomyślnie miejsce na udział w projekcie.'
      },
      'request': {
        'meeting': 'Wysłano pomyślnie zapytanie o udział w szkoleniu stacjonarnym.',
        'webinar': 'Wysłano pomyślnie zapytanie o udział w webinarze.',
        'course': 'Wysłano pomyślnie zapytanie o udział w szkoleniu.',
        'project': 'Wysłano pomyślnie zapytanie o udział w projekcie.'
      },
      'profile': {
        'updateSuccess': 'Zaktualizowałeś/-aś pomyślnie swój profil.',
        'updatePasswordSuccess': 'Zaktualizowałeś/-aś pomyślnie swoje hasło.'
      },
      'securityQuestion': {
        'questionSave': 'Zapisano pytanie bezpieczeństwa.'
      }
    }
  },
  'webservice': {
    'error': {
      'duplicate': 'Ta rezerwacja już istnieje!',
      'noaccess': 'Niedozwolony dostęp.',
      'notimplementet': 'Ta funkcja jest obecnie niedostępna.'
    },
    'success': {
      'booking': 'Rezerwacja zakończona pomyślnie.',
      'request': 'Pomyślnie wysłano zapytanie.',
      'cancel': 'Pomyślnie anulowano zamówienie. ',
      'waitlist': {
        'add': 'Zostałeś/-aś wpisany/-a na listę rezerwową'
      },
      'reservelist': {
        'add': 'Zostałeś/-aś wpisany/-a na listę osób zainteresowanych'
      }
    },
    'courseCalendar': {
      'noDate': 'Bez wyznaczonego terminu'
    }
  },
  'trainingmanager': {
    'headline': 'Wnioskowanie o doskonalenie zawodowe',
    'headlinerequest': 'Złóż wniosek',
    'bottonrequest': 'Złóż wniosek',
    'buttonmanage': 'Zarządzaj wnioskami',
    'requestform': {
      'requester': 'Wnioskodawca:',
      'date': 'Data:',
      'tab01': 'Złóż wniosek',
      'tab02': 'Złóż wniosek dot. grupy/osób trzecich',
      'tab01headline01': 'Imię i nazwisko uczestnika:',
      'tab01headline02': 'Tematyka doskonalenia zawodowego:',
      'tab01headline04': 'Imiona i nazwiska uczestników:',
      'tab01headline03': 'Szczegóły kursu:',
      'tab01label01': 'Tytuł:',
      'tab01label02': 'Imię:',
      'tab01label03': 'Nazwisko:',
      'tab01label04': 'Pan',
      'tab01label05': 'Pani',
      'tab01label06': 'Kategoria:',
      'tab01label07': 'Tytuł*:',
      'tab01label08': 'Krótki opis:',
      'tab01label09': 'Pozostałe:',
      'tab01label09O': 'Inne',
      'tab01label10': 'Miejsce powstawania kosztów:',
      'tab01label11': 'Termin (od/do):*',
      'tab01label12': 'Numer kursu:',
      'tab01label13': 'Cena:',
      'tab01label14': 'Sprzedawca:',
      'tab01label15': 'Załącznik:',
      'tab01label16': 'Wybierz plik',
      'tab01label17': 'Nie wybrano pliku...',
      'tab01label18': 'Wyślij wniosek',
      'tab01label19': 'Dodaj kolejną osobę',
      'tab01label20': 'Anuluj',
      'olheadline': 'Dodaj uczestnika',
      'olheadline1': 'Imię i nazwisko uczestnika',
      'olbodytext': 'Prosimy poniżej wybrać imiona i nazwiska pozostałych uczestników kursu.',
      'olerror': 'Brak uczestników. Prosimy zmienić kryteria wyszukiwania.'
    },
    'manage': {
      'headline': 'Zarządzaj wnioskami',
      'headlineInfo': 'Zapytania pracownika',
      'bodytextInfo': 'Poniżej można znaleźć wszystkie otwarte zapytania dot. doskonalenia zawodowego z prośbą o ich opracowanie.',
      'newRequest': 'Wyślij własny wniosek',
      'tableHeadline01': 'Wpłynął w dniu',
      'tableHeadline02': 'Wnioskodawca',
      'tableHeadline03': 'Nr kursu',
      'tableHeadline04': 'Tytuł',
      'tableHeadline05': 'Termin',
      'tableHeadline06': 'Cena',
      'tableMark': 'Zaznacz wszystkich',
      'buttonapprove': 'Zatwierdź wniosek',
      'buttondecline': 'Oddal wniosek',
      'modal': {
        'refuse': {
          'headline': 'Wiadomość - odrzuć wniosek',
          'placeholder': 'Prosimy wprowadzić tekst...',
          'checkbox': 'Odrzuć wniosek, nie wysyłając wiadomości',
          'button': 'Wyślij',
          'approval': 'Zatwierdzono wniosek',
          'refuse': 'Oddalono wniosek'
        }
      }
    }
  }
};
