// Ungarisch

module.exports = {
  "main_navigation": {
    "login": "Bejelentkezés",
    "registration": "Regisztráció",
    "courses": "Tanfolyamaim",
    "coursesDetail": "Tanfolyam részletei",
    "catalog": "Katalógus",
    "learningProfile": "Tanulási profil",
    "contact": "Kapcsolat",
    "profile": "Profil",
    "pwLost": "Elfelejtette a bejelentkezési adatait?",
    "about": "Impresszum",
    "terms_and_conditions": "Felhasználási feltételek / Adatvédelmi szabályzat",
    "logout": "Kijelentkezés",
    "faq": "GYIK",
    "trainingmanager": "Kérelmek",
    "trainingmanagerrequest": "Kérelem",
    "trainingmanagermanage": "Kezelés",
    "trainingmanagermanagedetail": "Részlet"
  },
  "general": {
    'offline': {
      'headline': 'Offline vagy!',
      'text': 'Ennek az alkalmazásnak aktív internetkapcsolatra van szüksége a megfelelő működéshez.',
    },
    "year": "Év",
    "month": "Hónap",
    "noResult": "Nem található eredmény.",
    "months": {
      "10": "Október",
      "11": "November",
      "12": "December",
      "01": "Január",
      "02": "Február",
      "03": "Március",
      "04": "Április",
      "05": "Május",
      "06": "Június",
      "07": "Július",
      "08": "Augusztus",
      "09": "Szeptember"
    },
    "category": "Kategória",
    "calendar": "Naptár",
    "search": "Keresés",
    "searchError": "Kérjük, legalább három karaktert írjon be.",
    "showLess": "Kevesebb megjelenítése",
    "showMore": "Több megjelenítése",
    'pagination': {
      'next': 'Következő',
      'prev': 'Előző',
      'separator': '/',
      'pageSize': 'Tételek oldalanként'
    }
  },
  "maintenance": {
    "title": "Karbantartás",
    "text": "Karbantartási munkálatok miatt az Akadémia jelenleg nem elérhető. Kérjük, próbálja újra később."
  },
  "password": {
    "requirements": {
      "title": "Jelszópolitika",
      "minimalLength": "Legalább #0 karakter hosszúnak kell lennie.",
      "mandantName": "\"A(z) \"\"#0\"\" kifejezés nem használható.\"",
      "names": "A jelszó nem lehet azonos a kereszt- vagy vezetéknévvel.",
      "userName": "A jelszó nem tartalmazhatja a felhasználónevet.",
      "userNameEqual": "A jelszó nem tartalmazhatja a felhasználónevet.",
      "bigLetters": "Legalább #0 nagybetűt kell tartalmaznia.",
      "smallLetters": "Legalább #0 kisbetűt kell tartalmaznia.",
      "digits": "Legalább #0 számot kell tartalmaznia.",
      "sameLetters": "Legfeljebb #0 karakter követheti egymást.",
      "oldPassword": "Nem lehet a régi jelszó.",
      "birthday": "A jelszó nem egyezhet meg a saját születési dátummal.",
      "minSymbolsPolicy": "Legalább #0 speciális karaktert kell tartalmaznia.",
      "minPasswordLength": "A jelszónak legalább #0 karakter hosszúnak kell lennie.",
      "maxPasswordAttempts": "Még #0 próbálkozása van a jelszó helyes megadására."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'Are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Sajnáljuk!",
      "text1": "A kért oldal nem található",
      "text2": "Használja a böngészője vissza gombját, vagy lépjen vissza a kezdőlapra.",
      "button": "Vissza a kezdőlapra"
    },
    "courseCalendar": {
      "expired": "Lejárt",
      "soonExpired": "Hamarosan lejár",
      'expiredMeetings': 'Korábbi időpontok'
    },
    "profile": {
      "userdata": "Felhasználói adatok",
      "password": "Jelszó",
      "genderLab": "Megszólítás",
      "gender": {
        "men": "Úr",
        "woman": "Asszony",
        "diverse": "Diverz",
        "unknown": "Nincs megadva"
      },
      "firstname": "Keresztnév",
      "lastname": "Vezetéknév",
      "email": "E-mail",
      "strasse": "Utca / Házszám",
      "city": "Irányítószám / Város",
      "phone": "Telefon",
      "fax": "Fax",
      "position": "Pozíció",
      "department": "Részleg",
      "required": "Kötelező mező",
      "oldPassword": "Régi jelszó",
      "newPassword": "Új jelszó",
      "newPasswordRep": "Ismétlés",
      "company": "Vállalat",
      "language": "Nyelv"
    },
    "catalog": {
      "back": "Vissza",
      "bookingDialog": {
        "notice": "Itt adja meg a képzési kérelem okát"
      }
    },
    "contact": {
      "info": "Kérjük, írja be kérését az üzenetmezőbe. Ha egy konkrét képzésre hivatkozik, kérjük, adja meg azt: Üzenete így közvetlenül a megfelelő helyre kerül továbbításra.",
      "trainingSelection": "Tanfolyamválaszték",
      "yourMessage": "Az Ön üzenete",
      "success": "Az üzenetét sikeresen elküldtük!",
      "otherReason": "Általános kapcsolatfelvételi kérelem",
      "otherReasonSubject": "Egyéb kérelem",
      "chooseReason": "Kérjük, válasszon"
    },
    "learningProfile": {
      "title": "Tanulási profil",
      "unit": "Tanulási egységek",
      "points": "Összpontszám",
      "history": "Tanulási történet",
      "restoreFilter": "Szűrő visszaállítása",
      "certificate": "Tanúsítvány",
      'downloadAll': 'Minden letöltése',
      "withoutCategory": "Kategória nélkül",
      "tooltip01": "Teljesített tanegységek száma:",
      "tooltip02": "/",
      "tooltip03": ".",
      "gamification": {
        "badge": {
          "title": "Kitüntetések",
          "congratulations": "Gratulálunk!",
          "achieved": "Újabb kitüntetést kapott!"
        }
      }
    },
    "courseDetail": {
      "title": "Tanfolyam részletei",
      "back": "Vissza a listához",
      "progress": "Előrehaladás",
      "expiry": "érvényes eddig:",
      "classroomNo": "Képzési szám",
      "contactPerson": "Kapcsolattartó",
      "targetGroup": "Célcsoport",
      "bonusPoints": "Bónusz pontok",
      "classroomContent": "Képzés tartalma",
      "webinarLight": "Webinárium információk",
      "specials": "Különlegességek",
      "stornoConditions": "Lemondási feltételek",
      "meeting": "Időpont",
      "meetings": "Időpontok",
      'freeDay': 'Free day',
      'freeDayInfo': 'A szabad napok nincsenek fent felsorolva',
      "speaker": "Oktató / előadó",
      "location": "Képzés helye",
      "requested": "KERESETT",
      "waitlist": "VÁRÓLISTÁN",
      "fullyBooked": "BETELT",
      "edit": "Szerkesztés",
      "webinarSpeaker": "Referens",
      "docsAndLinks": "Dokumentumok és linkek",
      "links": "Linkek",
      "webinar": {
        "host": "Webinárium megnyitása",
        "state": {
          "ended": "A webinárium véget ért",
          "beforeStart": "A webinárium ekkor kezdődik: "
        }
      },
      "sidebar": {
        "price": "Ár",
        "duration": "Tanfolyam időtartama",
        "to": "eddig:",
        "numberOfParticipants": "Résztvevők száma",
        "status": "Foglalás állapota",
        'totalStatus': 'Általános állapot',
        'contentStatus': 'Képzési státusz'
      },
      "bookingInformation": {
        "title": "Foglalási információk",
        "note": "Megjegyzés",
        "accounts": "Költségelszámolási egység",
        "invoiceRecipient": "Számla kedvezményezettje",
        "invoiceAddress": "Számlázási cím",
        "food": "Étkezési preferencia",
        "arrival": "Érkezés",
        "arrivalType": {
          "none": "Nincs megadva",
          "car": "Autó",
          "train": "Vonat",
          "plane": "Repülőgép"
        },
        "arrivalDate": "Érkezés időpontja",
        "departure": "Távozás",
        "departureDate": "Távozás időpontja",
        "flightNumber": "Járat száma",
        "trainNumber": "Vonatszám",
        "railwayStation": "Vasútállomás",
        'placeholder': {
          'departure_airport': 'Indulási repülőtérn',
          'destination_airport': 'Célállomás repülőtér',
          'departure_trainstation': 'Indulási állomás',
          'destination_trainstation': 'Célállomás',
          'departure_car': 'Indulási hely',
          'destination_car': 'Úticél hely',
          'from': 'a címről',
          'to': 'a címre'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          "Január",
          "Február",
          "Március",
          "Április",
          "Május",
          "Június",
          "Július",
          "Augusztus",
          "Szeptember",
          "Október",
          "November",
          "December"
        ]
      },
      "reserve": {
        "title": "Nem megfelelő a megadott időpont?",
        "text": "Jelezze érdeklődését! Ezt követően értesítjük Önt a lehetséges új időpontokról.",
        "button": "Feliratkozás",
        "prebooked": {
          "text": "Ezennel jelezte érdeklődését a képzés iránt."
        }
      },
      'downloads': {
        'modalHeadline': 'Letöltések',
        'sidebarLink': 'Dokumentum(ok)',
      }
    },
    "course": {
      "type": {
        "0": "Online tanfolyam",
        "1": "Online tanfolyam",
        "2": "Online tanfolyam",
        "3": "Online tanfolyam",
        "4": "Online tanfolyam",
        "5": "Online tanfolyam",
        "6": "Tantermi tanfolyam",
        "7": "Webinárium",
        "-2": "Projekt",
        "undefined": "-"
      },
      "status": {
        "open": "Nyitott",
        'sent': 'Sent',
        "passed": "Átment",
        'sent': 'Küldött',
        "failed": "Nem ment át",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Lejárt",
        "booked": "Lefoglalva",
        "started": "Feldolgozás alatt",
        "upcoming": "Közelgő",
        "running": "Folyamatban lévő",
        "completed": "Befejezett",
        "notyet": "Még nem elérhető"
      },
      "notification": {
        "success1": "Ön ",
        "success2": " átment a tanfolyamon!"
      },
      'warning': {
        'headline': 'Figyelem!',
        'body': {
          '1': 'A képzéshez más nyelvet választott, mint a legutóbbi alkalommal.',
          '2': 'Ha folytatja, és a \'Kiképzés megkezdése\' gombra kattint, az előrehaladás visszaállításra kerül.',
        },
        'buttons': {
          'start': 'Kiképzés megkezdése',
          'startPrevious': 'Előző nyelv kiválasztása',
        }
      },
      "statusLabel": {
        "training": "Tanfolyam",
        'content': 'Tartalom',
        "exam": "Vizsga",
        'attendance': 'Attendance',
        "survey": "Felmérés",
        "efficacyControl": "Hatékonyságellenőrzés"
      },
      "start": {
        "survey": "Felmérés indítása",
        "exam": "Vizsga indítása",
        "efficacyControl": "Hatékonyságellenőrzés indítása"
      },
      "prefix": {
        "category": "Kategória",
        "state": "Állapot",
        "type": "Tanfolyam típusa"
      },
      "noAssigned": "Nincs Önhöz rendelve egyetlen tanfolyam sem.",
      "noSolved": "\"Még nem végzett el egyetlen tanfolyamot sem. Kezdjen bele most a \"\"Saját képzéseim\"\" menüpont alatt az első tanulási egységbe!\"",
      "noAssignedBookable": "Nincs Önhöz rendelve egyetlen foglalható tanfolyam sem.",
      "noResult": "Nem találtunk a kritériumoknak megfelelő tanfolyamot.",
      "loadingFailed": "A kért rekordokat ismeretlen hiba miatt nem lehetett betölteni!",
      "noResultInCategory": "Ehhez a kategóriához még nem rendeltek hozzá tanfolyamokat.",
      "meeting": {
        "noMeeting": "nincs megadott időpont",
        "multipleMeetings": "kül. időpontok"
      },
      "project": {
        "courses": "Tanfolyamok"
      }
    },
    "login": {
      "invalidUser": "Érvénytelen bejelentkezés. A felhasználónév / jelszó hibás!",
      "invalidPassword": "Érvénytelen bejelentkezés. Még #0 próbálkozása van a jelszó helyes megadására.",
      "accountBlocked": "A fiókját felfüggesztettük. Kérjük, vegye fel a kapcsolatot egy felelős munkatárssal.",
      "error": "Hibás felhasználónév és/vagy jelszó!",
      "empty": "Kérjük, töltse ki az összes mezőt.",
      "back": "Vissza a bejelentkezéshez",
      "description": "Kérjük, adja meg felhasználónevét vagy e-mail címét, hogy újra el tudjuk küldeni a bejelentkezési adatokat.",
      "password-reset": {
        "description": "Kérjük, válasszon új jelszót a felhasználói fiókjához.",
        "success": "A jelszavát sikeresen megváltoztattuk.",
        "error": "A megadott jelszavak nem egyeznek."
      },
      "placeholder": " Felhasználónév / E-mail cím",
      "button": "Hozzáférési adatok lekérdezése",
      "success": "A kérését sikeresen továbbítottuk.",
      "errorForgot": "Nem találtunk a megadott feltételeknek megfelelő felhasználót.",
      "errorForgotSecurityQuestion": "A válasz sajnos helytelen!"
    },
    "registration": {
      "description": "Ha még nem rendelkezik bejelentkezési adatokkal, akkor egyszerűen regisztráljon a vállalat promóciós kódjával. Ezt követően azonnal küldünk Önnek egy e-mailt.",
      "actionCodeError": "A megadott regisztrációs kód érvénytelen",
      "actionCodeLimitExceeded": "Elérte a megadott promóciós kódhoz tartozó regisztrációk maximális számát.",
      "registerDescription": "Kérjük, töltse ki az alábbi űrlapot. A hozzáférési adatokat néhány percen belül e-mailben elküldjük Önnek.",
      "title": "Az Ön Akadémiai regisztrációja",
      "required": "Kötelező mező*",
      "emailError": "A megadott e-mail cím már foglalt.",
      "emailErrorNotAllowed": "Ezzel az e-mail címmel nem lehetséges a regisztráció.",
      "send": "Küldés",
      "completeMessage": "Sikeresen regisztrált az Akadémiára!"
    },
    "dataProtection": {
      "accept": "Beleegyezés",
      "acceptText": "Igen, elfogadom a felhasználási feltételeket / adatvédelmi szabályzatot."
    },
    "securityQuestion": {
      "title": "Biztonsági kérdés",
      "chooseQuestion": "Kérjük, válasszon",
      "questionSelection": "Kérjük, válasszon egy kérdést",
      "yourAnswer": "Kérjük, adja meg a válaszát",
      "description": "Kérjük, válaszoljon a biztonsági kérdésre, és válasszon új jelszót a felhasználói fiókjához."
    },
    "faq": {
      "title": "Gyakori kérdések"
    }
  },
  "sidebar": {
    "all": "Összes",
    "categories": "KATEGÓRIÁK",
    "status": "ÁLLAPOT",
    "courseType": "TANFOLYAM TÍPUSA",
    "topics": "TÉMAKÖRÖK"
  },
  "placeholder": {
    "username": "Felhasználónév",
    "password": "Jelszó",
    "actionCode": "Promóciós kód"
  },
  "button": {
    "login": "BEJELENTKEZÉS",
    "login-sso": "ACTIVE-DIRECTORY BEJELENTKEZÉS",
    "send": "KÜLDÉS",
    "start_course": "INDÍTÁS",
    "changePassword": "Jelszó módosítása",
    "reset": "Visszaállítás",
    "save": "Mentés",
    "request": "Kérelem",
    "storno": "Visszavonás",
    "close": "Bezárás",
    "booking": "Foglalás",
    "waitlist": "Várólista",
    "start_webinar": "Webinárium indítása",
    "open_webinar": "Webinárium megnyitása",
    'cancel': 'Törölje',
    'confirm': 'Megerősítés'
  },
  "bookingState": {
    "0": "KERESETT",
    "1": "LEFOGLALVA",
    "2": "VÁRÓLISTÁN",
    "3": "VISSZAVONVA",
    "4": "VISSZAVONVA",
    "5": "ELUTASÍTVA",
    "6": "ELŐJEGYEZVE",
    "onlineTraining": {
      "0": "KERESETT",
      "1": "LEFOGLALVA",
      "2": "ELUTASÍTVA"
    }
  },
  "foodPreference": {
    "none": "Nincs",
    "lactoseIntolerance": "Laktózérzékenység",
    "vegetarian": "Vegetáriánus",
    "vegan": "Vegán",
    "nutAllergy": "Mogyoró allergia",
    "diabetic": "Cukorbetegség",
    "glutenFree": "Gluténmentes",
    "kosher": "Kóser",
    "halal": "Halal",
    "other": "Egyéb"
  },
  "notifications": {
    "error": "Hiba történt!",
    "errors": {
      "missingInput": "Kérjük, töltse ki a mezőket",
      "imageLoad": "A kért képet nem sikerült betölteni"
    },
    "success": {
      "book": {
        "meeting": "Sikeresen jelentkezett a tantermi tanfolyamra.",
        "webinar": "Sikeresen jelentkezett a webináriumra.",
        "course": "Sikeresen jelentkezett a tanfolyamra.",
        "project": "Sikeresen jelentkezett a projektre."
      },
      "request": {
        "meeting": "Sikeresen kérelmezte a tantermi tanfolyamra való jelentkezését.",
        "webinar": "Sikeresen kérelmezte a webináriumra való jelentkezését.",
        "course": "Sikeresen kérelmezte a tanfolyamra való jelentkezését.",
        "project": "Sikeresen kérelmezte a projektre való jelentkezését."
      },
      "profile": {
        "updateSuccess": "Sikeresen frissítette profilját!",
        "updatePasswordSuccess": "Sikeresen frissítette jelszavát!"
      },
      "securityQuestion": {
        "questionSave": "A biztonsági kérdés mentésre került!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Már létezik ilyen foglalás!",
      "noaccess": "A hozzáférés nem engedélyezett.",
      "notimplementet": "A funkció jelenleg nem elérhető."
    },
    "success": {
      "booking": "Sikeresen jelentkezett.",
      "request": "Sikeresen kérelmezte a jelentkezését.",
      "cancel": "Sikeresen visszamondta a jelentkezését. ",
      "waitlist": {
        "add": "Felkerült a várólistára"
      },
      "reservelist": {
        "add": "Előjegyzésre került"
      }
    },
    "courseCalendar": {
      "noDate": "Határidő nélkül"
    }
  },
  "trainingmanager": {
    "headline": "Továbbképzéssel kapcsolatos igények",
    "headlinerequest": "Kérvény benyújtása",
    "bottonrequest": "Kérvény benyújtása",
    "buttonmanage": "Kérvények feldolgozása",
    "requestform": {
      "requester": "Kérvényező:",
      "date": "Dátum:",
      "tab01": "Igény benyújtása",
      "tab02": "Csoportra/harmadik félre vonatkozó igény",
      "tab01headline01": "Résztvevő neve:",
      "tab01headline02": "Továbbképzés témája:",
      "tab01headline04": "Résztvevők neve:",
      "tab01headline03": "Tanfolyam részletei:",
      "tab01label01": "Megszólítás:",
      "tab01label02": "Keresztnév:",
      "tab01label03": "Vezetéknév:",
      "tab01label04": "Úr",
      "tab01label05": "Asszony",
      "tab01label06": "Kategória:",
      "tab01label07": "Cím*:",
      "tab01label08": "Rövid leírás:",
      "tab01label09": "Egyéb:",
      "tab01label09O": "Egyéb",
      "tab01label10": "Költségelszámolási egység:",
      "tab01label11": "Időpont (ettől/eddig):",
      "tab01label12": "Tanfolyam száma:",
      "tab01label13": "Ár:",
      "tab01label14": "Szolgáltató:",
      "tab01label15": "Melléklet:",
      "tab01label16": "Fájl kiválasztása",
      "tab01label17": "Nincs kiválasztott fájl...",
      "tab01label18": "Kérvény benyújtása",
      "tab01label19": "További személy hozzáadása",
      "tab01label20": "Megszakítás",
      "olheadline": "Résztvevő hozzáadása",
      "olheadline1": "Résztvevő neve",
      "olbodytext": "Az alábbiakban válassza ki a többi tanfolyami résztvevő nevét.",
      "olerror": "Nem találtunk résztvevőket. Kérjük, módosítsa a keresési paramétereket."
    },
    "manage": {
      "headline": "Kérvények feldolgozása",
      "headlineInfo": "Munkavállalói kérvények",
      "bodytextInfo": "Az alábbiakban megtalálja az összes nyitott képzési kérelmet a feldolgozásra vonatkozó kérelemmel együtt.",
      "newRequest": "Saját kérvény benyújtása",
      "tableHeadline01": "Bevitel",
      "tableHeadline02": "Kérvényező",
      "tableHeadline03": "Tanfolyam száma",
      "tableHeadline04": "Cím",
      "tableHeadline05": "Időpont",
      "tableHeadline06": "Ár",
      "tableMark": "Összes kijelölése",
      "buttonapprove": "Jóváhagyás",
      "buttondecline": "Elutasítás",
      "modal": {
        "refuse": {
          "headline": "Üzenet - Igény elutasítása",
          "placeholder": "Kérjük, írja be a szöveget....",
          "checkbox": "Igény elutasítása szöveg nélkül",
          "button": "Küldés",
          "approval": "Jóváhagyva",
          "refuse": "Elutasítva"
        }
      }
    }
  }
}
