// Slowenisch

module.exports = {
  "main_navigation": {
    "login": "Vpis",
    "registration": "Registracija",
    "courses": "Moji treningi",
    "coursesDetail": "Podrobnosti treninga",
    "catalog": "Katalog",
    "learningProfile": "Učni profil",
    "contact": "Kontakt",
    "profile": "Profil",
    "pwLost": "Ste pozabili podatke za vpis?",
    "about": "Impresum",
    "terms_and_conditions": "Pogoji koriščenja / varstvo podatkov",
    "logout": "Odjava",
    "faq": "FAQ",
    "trainingmanager": "Vloge",
    "trainingmanagerrequest": "Povpraševanje",
    "trainingmanagermanage": "Upravljajte",
    "trainingmanagermanagedetail": "Podrobnost"
  },
  "general": {
    'offline': {
      'headline': 'Ste brez povezave!',
      'text': 'Ta aplikacija za pravilno delovanje potrebuje aktivno internetno povezavo.',
    },
    "year": "leto",
    "month": "mesec",
    "noResult": "Najden ni bil noben rezultat.",
    "months": {
      "10": "oktober",
      "11": "november",
      "12": "december",
      "01": "januar",
      "02": "februar",
      "03": "marec",
      "04": "april",
      "05": "maj",
      "06": "junij",
      "07": "julij",
      "08": "avgust",
      "09": "september"
    },
    "category": "kategorija",
    "calendar": "koledar",
    "search": "Iskanje",
    "searchError": "Vnesite vsaj tri znake.",
    "showLess": "Prikaži manj",
    "showMore": "Prikaži več",
    'pagination': {
      'next': 'Naslednji',
      'prev': 'Prejšnji',
      'separator': 'od',
      'pageSize': 'Elementi na stran'
    }
  },
  "maintenance": {
    "title": "Vzdrževalna dela",
    "text": "Zaradi vzdrževalnih del Akademija trenutno ni na voljo. Prosimo, poskusite ponovno pozneje."
  },
  "password": {
    "requirements": {
      "title": "Smernice za geslo",
      "minimalLength": "Dolžina mora biti najmanj #0.",
      "mandantName": "\"Pojma \"\"#0\"\" se ne sme uporabiti.\"",
      "names": "Geslo ne sme biti ime ali priimek.",
      "userName": "Geslo ne sme vsebovati vaših uporabniških imen.",
      "userNameEqual": "Geslo ne sme vsebovati vaših uporabniških imen.",
      "bigLetters": "Vsebovati mora/morajo najmanj #0 velikih črk.",
      "smallLetters": "Vsebovati mora/morajo najmanj #0 malih črk.",
      "digits": "Vsebovati mora/morajo najmanj #0 številko/številk.",
      "sameLetters": "Ne sme si slediti več kot #0 znakov.",
      "oldPassword": "Ne sme biti staro geslo.",
      "birthday": "Geslo ne sme biti vaš rojstni datum.",
      "minSymbolsPolicy": "Vsebovati mora/morajo najmanj #0 posebnih znakov.",
      "minPasswordLength": "Geslo mora biti dolgo najmanj #0 znakov.",
      "maxPasswordAttempts": "Imate še #0 preostalih poskusov, da vnesete pravilno geslo."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'Are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Žal nam je!",
      "text1": "Zahtevana stran ni bila najdena.",
      "text2": "Pritisnite tipko za vrnitev nazaj na vašem brskalniku ali pojdite na začetno stran.",
      "button": "Nazaj na začetno stran"
    },
    "courseCalendar": {
      "expired": "Preteklo",
      "soonExpired": "Kmalu bo preteklo",
      'expiredMeetings': 'Pretekli datumi'
    },
    "profile": {
      "userdata": "Uporabniški podatki",
      "password": "Geslo",
      "genderLab": "Nagovor",
      "gender": {
        "men": "Gospod",
        "woman": "Gospa",
        "diverse": "Se razlikuje",
        "unknown": "Brez navedbe"
      },
      "firstname": "Ime",
      "lastname": "Priimek",
      "email": "el.- pošta",
      "strasse": "Ulica/cesta št.",
      "city": "Poštna številka / kraj",
      "phone": "Telefon",
      "fax": "Telefaks",
      "position": "Položaj",
      "department": "Oddelek",
      "required": "Obvezno polje",
      "oldPassword": "Staro geslo",
      "newPassword": "Novo geslo",
      "newPasswordRep": "Ponovitev",
      "company": "Podjetje",
      "language": "Jezik"
    },
    "catalog": {
      "back": "Nazaj",
      "bookingDialog": {
        "notice": "Vpišite svojo utemeljitev za povpraševanje o treningu"
      }
    },
    "contact": {
      "info": "Prosimo, da svoje povpraševanje vpišete v polje za sporočila Če se sklicujete na poseben trening, prosimo, da ga vpišete: Vaše sporočilo bo lahko tako posredovano neposredno na pravo mesto.",
      "trainingSelection": "Izbira treninga",
      "yourMessage": "Vaše sporočilo",
      "success": "Vaše sporočilo je bilo uspešno poslano!",
      "otherReason": "Splošno povpraševanje o stikih",
      "otherReasonSubject": "Drugo povpraševanje",
      "chooseReason": "Prosimo izberite"
    },
    "learningProfile": {
      "title": "Učni profil",
      "unit": "Učne enote",
      "points": "Skupno število točk",
      "history": "Zgodovina učenja",
      "restoreFilter": "Ponastavitev filtra",
      "certificate": "Certifikat",
      'downloadAll': 'Prenesi vse',
      "withoutCategory": "Brez kategorije",
      "tooltip01": "Absolvirali",
      "tooltip02": "ste",
      "tooltip03": "učnih enot.",
      "gamification": {
        "badge": {
          "title": "Priznanja",
          "congratulations": "Čestitamo!",
          "achieved": "Prejeli ste novo priznanje!"
        }
      }
    },
    "courseDetail": {
      "title": "Podrobnosti treninga",
      "back": "Nazaj na seznam",
      "progress": "Napredek",
      "expiry": "veljavno do",
      "classroomNo": "Številka šolanja",
      "contactPerson": "Kontaktna oseba",
      "targetGroup": "Ciljna skupina",
      "bonusPoints": "Bonus točke",
      "classroomContent": "Vsebine šolanja",
      "webinarLight": "Informacije o webinarju",
      "specials": "Posebnosti",
      "stornoConditions": "Pogoji storniranja",
      "meeting": "Termina",
      "meetings": "Termini",
      'freeDay': 'Free day',
      'freeDayInfo': 'Slobodni dani nisu navedeni gore\n',
      "speaker": "Trener / referent",
      "location": "Kraj šolanja",
      "requested": "POVPRAŠEVANJE ODDANO",
      "waitlist": "ČAKALNI SEZNAM",
      "fullyBooked": "ZASEDENO",
      "edit": "Obdelati",
      "webinarSpeaker": "Referent",
      "docsAndLinks": "Dokumenti in povezave",
      "links": "Povezave",
      "webinar": {
        "host": "Odpreti webinar",
        "state": {
          "ended": "Webinar je končan",
          "beforeStart": "Webinar se začne dne "
        }
      },
      "sidebar": {
        "price": "Cena na osebo",
        "duration": "Trajanje učenja",
        "to": "do",
        "numberOfParticipants": "Število udeležencev",
        "status": "Status rezervacije",
        'totalStatus': 'Splošno stanje',
        'contentStatus': 'Stanje usposabljanja'
      },
      "bookingInformation": {
        "title": "Informacija o rezervaciji",
        "note": "Opomba",
        "accounts": "Stroškovno mesto",
        "invoiceRecipient": "Prejemnik računa",
        "invoiceAddress": "Naslov računa",
        "food": "Želena oskrba",
        "arrival": "Prihod",
        "arrivalType": {
          "none": "Brez navedbe",
          "car": "Avto",
          "train": "Vlak",
          "plane": "Letalo"
        },
        "arrivalDate": "Datum prihoda",
        "departure": "Odhoda",
        "departureDate": "Datuma odhoda",
        "flightNumber": "Številka leta",
        "trainNumber": "Številka vlaka",
        "railwayStation": "Železniška postaja",
        'placeholder': {
          'departure_airport': 'Letališče odhoda',
          'destination_airport': 'Ciljno letališče',
          'departure_trainstation': 'Postaja odhoda',
          'destination_trainstation': 'Namembna postaja',
          'departure_car': 'Kraj odhoda',
          'destination_car': 'Kraj destinacije',
          'from': 's spletne strani',
          'to': 'na'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          "januar",
          "februar",
          "marec",
          "april",
          "maj",
          "junij",
          "julij",
          "avgust",
          "september",
          "oktober",
          "november",
          "december"
        ]
      },
      "reserve": {
        "title": "Ne morete urediti termina/terminov?",
        "text": "Zabeležite svoje zanimanje! O možnih novih terminih boste informirani.",
        "button": "Zabeležite si",
        "prebooked": {
          "text": "Zabeležili ste svoj interes za ta trening"
        }
      },
      'downloads': {
        'modalHeadline': 'Prenosi',
        'sidebarLink': 'Dokument(i)',
      }
    },
    "course": {
      "type": {
        "0": "Online trening",
        "1": "Online trening",
        "2": "Online trening",
        "3": "Online trening",
        "4": "Online trening",
        "5": "Online trening",
        "6": "Trening v živo",
        "7": "Webinar",
        "-2": "Projekt",
        "undefined": "-"
      },
      "status": {
        "open": "Odprto",
        'sent': 'Sent',
        "passed": "Opravil/a",
        'sent': 'Poslano',
        "failed": "Ni opravil",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Preteklo",
        "booked": "Rezervirano",
        "started": "V obdelavi",
        "upcoming": "Bližajoče se",
        "running": "Traja",
        "completed": "Zaključeno",
        "notyet": "Še ni na voljo"
      },
      "notification": {
        "success1": "Trening ",
        "success2": " ste opravili!"
      },
      'warning': {
        'headline': 'Pozor!',
        'body': {
          '1': 'Za to usposabljanje ste izbrali drug jezik kot zadnjič.',
          '2': 'Če nadaljujete in kliknete na "Začni usposabljanje", se napredek ponastavi.',
        },
        'buttons': {
          'start': 'Začni usposabljanje',
          'startPrevious': 'Izberite prejšnji jezik',
        }
      },
      "statusLabel": {
        "training": "Trening",
        'content': 'Content',
        'attendance': 'Attendance',
        "exam": "Preskus",
        "survey": "Izpraševanje",
        "efficacyControl": "Kontrola učinkovitosti"
      },
      "start": {
        "survey": "Začetek izpraševanja",
        "exam": "Začetek preskusa",
        "efficacyControl": "Začnite kontrolo učinkovitosti"
      },
      "prefix": {
        "category": "Kategorija",
        "state": "Status",
        "type": "Tip treninga"
      },
      "noAssigned": "Dodeljeni vam niso nobeni treningi.",
      "noSolved": "\"Niste še absolvirali nobenih treningov. Začnite na področju »Moji treningi\"\" z vašo prvo učno enoto!\"",
      "noAssignedBookable": "Dodeljeni vam niso nobeni treningi, ki bi jih bilo mogoče rezervirati.",
      "noResult": "Najdeni niso bili nobeni treningi, ki bi ustrezali kriterijem.",
      "loadingFailed": "Zahtevanih zapisov podatkov zaradi neznane napake ni bilo mogoče naložiti.",
      "noResultInCategory": "Kategoriji niso bili dodeljeni še nobeni treningi.",
      "meeting": {
        "noMeeting": "ni termina",
        "multipleMeetings": "razl. termini"
      },
      "project": {
        "courses": "treningi"
      }
    },
    "login": {
      "invalidUser": "Neveljaven vpis Uporabniško ime / geslo je napačno!",
      "invalidPassword": "Neveljaven vpis. Imate še #0 preostalih poskusov, da vnesete pravilno geslo.",
      "accountBlocked": "Vaš račun je bil blokiran. Prosimo, obrnite se na pristojnega sodelavca.",
      "error": "Napačno uporabniško ime in/ali geslo!",
      "empty": "Prosimo, izpolnite vsa polja.",
      "back": "Nazaj na vpis",
      "description": "Prosimo, vpišite svoje uporabniško ime ali naslov el. pošte za ponovno posredovanje podatkov za dostop.",
      "password-reset": {
        "description": "Prosimo izberite novo geslo za vaš uporabniški račun.",
        "success": "Vaše geslo je bilo uspešno spremenjeno.",
        "error": "Navedeni gesli se ne ujemata."
      },
      "placeholder": " Uporabniško ime / naslov el. pošte",
      "button": "Zahtevanje podatkov za dostop",
      "success": "Vaše povpraševanje je bilo uspešno poslano.",
      "errorForgot": "Najden ni bil noben uporabnik, ki bi ustrezal navedenim kriterijem.",
      "errorForgotSecurityQuestion": "Vaš odgovor na žalost ni pravilen!"
    },
    "registration": {
      "description": "Če še nimate podatkov za vpis, se lahko regitrirate z akcijsko kodo vašega podjetja. Po tem vam bo takoj poslano prek elektronske pošte.",
      "actionCodeError": "Vpisana koda za registriranje je neveljavna.",
      "actionCodeLimitExceeded": "Maksimalno število za možne registracije za navedeno akcijsko kodo je porabljeno.",
      "registerDescription": "Prosimo izpolnite spodnji formular. Vaši podatki za dostop vam bodo v nekaj minutaj poslani prek elektronske pošte.",
      "title": "Vaša Akademija registracija",
      "required": "Obvezno polje*",
      "emailError": "Naslov elektronske pošte, ki ste ga navedli, je že oddan.",
      "emailErrorNotAllowed": "S tem naslovom elektronske pošte registracija ni mogoča.",
      "send": "Odposlati",
      "completeMessage": "Uspešno ste se registrirali pri Akademiji!"
    },
    "dataProtection": {
      "accept": "Soglašati",
      "acceptText": "Da, soglašam s pogoji koriščenja / varstvom podatkov."
    },
    "securityQuestion": {
      "title": "Varnostna poizvedba",
      "chooseQuestion": "Prosimo izberite",
      "questionSelection": "Prosimo izberite eno vprašanje",
      "yourAnswer": "Prosimo vpišite odgovor",
      "description": "Prosimo, odgovorite na varnostno poizvedbo in izberite novo geslo za vaš uporabniški račun."
    },
    "faq": {
      "title": "Pogosto zastavljena vprašanja"
    }
  },
  "sidebar": {
    "all": "Vse",
    "categories": "KATEGORIJE",
    "status": "STATUS",
    "courseType": "TIP TRENINGA",
    "topics": "TEMATSKA PODROČJA"
  },
  "placeholder": {
    "username": "Uporabniško ime",
    "password": "Geslo",
    "actionCode": "Akcijska koda"
  },
  "button": {
    "login": "PRIJAVA",
    "login-sso": "VPIS V AKTIVNI DIREKTORIJ",
    "send": "POŠLJI",
    "start_course": "ZAČNI",
    "changePassword": "Spremeni geslo",
    "reset": "Ponastavi",
    "save": "Shrani",
    "request": "Povpraševanje",
    "storno": "Storniranje",
    "close": "Zapri",
    "booking": "Rezerviraj",
    "waitlist": "Čakalni seznam",
    "start_webinar": "Začni webinar",
    "open_webinar": "Odpri webinar",
    'cancel': 'Prekliči',
    'confirm': 'Potrdite'
  },
  "bookingState": {
    "0": "POVPRAŠEVANJE ODDANO",
    "1": "REZERVIRANO",
    "2": "ČAKALNI SEZNAM",
    "3": "STORNIRANO",
    "4": "STORNIRANO",
    "5": "ZAVRNJENO",
    "6": "ZABELEŽENO",
    "onlineTraining": {
      "0": "POVPRAŠEVANJE ODDANO",
      "1": "REZERVIRANO",
      "2": "ZAVRNJENO"
    }
  },
  "foodPreference": {
    "none": "Nobene",
    "lactoseIntolerance": "Laktozna intoleranca",
    "vegetarian": "Vegetarijansko",
    "vegan": "Vegansko",
    "nutAllergy": "Alergija na oreščke",
    "diabetic": "Diabetes",
    "glutenFree": "Brezglutensko",
    "kosher": "Košer",
    "halal": "Halal",
    "other": "Drugo"
  },
  "notifications": {
    "error": "Nekaj je šlo narobe!",
    "errors": {
      "missingInput": "Prosimo, izpolnite polja",
      "imageLoad": "Zahtevane slike ni bilo mogoče naložiti"
    },
    "success": {
      "book": {
        "meeting": "Uspešno ste rezervirali trening z navzočnostjo.",
        "webinar": "Uspešno ste rezervirali webinar.",
        "course": "Uspešno ste rezervirali trening.",
        "project": "Uspešno ste rezervirali projekt."
      },
      "request": {
        "meeting": "Uspešno ste izvedli povpraševanje za trening z navzočnostjo.",
        "webinar": "Uspešno ste izvedli povpraševanje za webinar.",
        "course": "Uspešno ste izvedli povpraševanje za trening.",
        "project": "Uspešno ste izvedli povpraševanje za projekt."
      },
      "profile": {
        "updateSuccess": "Uspešno ste aktualizirali svoj profil!",
        "updatePasswordSuccess": "Uspešno ste aktualizirali svoje geslo!"
      },
      "securityQuestion": {
        "questionSave": "Vaša varnostna poizvedba je bila shranjena!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Rezervacija že obstaja!",
      "noaccess": "Dostop ni dovoljen.",
      "notimplementet": "Funkcija trenutno ni na voljo."
    },
    "success": {
      "booking": "Uspešno ste izvedli rezervacijo.",
      "request": "Uspešno ste izvedli povpraševanje.",
      "cancel": "Uspešno ste izvedli storniranje. ",
      "waitlist": {
        "add": "Vpisani ste bili na čakalni seznam"
      },
      "reservelist": {
        "add": "Vpisani ste bili na seznam za rezervacije"
      }
    },
    "courseCalendar": {
      "noDate": "Brez roka"
    }
  },
  "trainingmanager": {
    "headline": "Zahteva za nadaljnje izobraževanje",
    "headlinerequest": "Vloga",
    "bottonrequest": "Vloga",
    "buttonmanage": "Upravljanje vlog",
    "requestform": {
      "requester": "Predlagatelj:",
      "date": "Datum:",
      "tab01": "Zahteva",
      "tab02": "Zahteva za skupino/tretje osebe",
      "tab01headline01": "Ime udeleženca",
      "tab01headline02": "Tema nadaljnjega izobraževanja:",
      "tab01headline04": "Ime udeležencev:",
      "tab01headline03": "Podrobnosti tečaja:",
      "tab01label01": "Nagovor:",
      "tab01label02": "Ime:",
      "tab01label03": "Priimek:",
      "tab01label04": "Gospod",
      "tab01label05": "Gospa",
      "tab01label06": "Kategorija:",
      "tab01label07": "Naziv*:",
      "tab01label08": "Kratek opis:",
      "tab01label09": "Drugo:",
      "tab01label09O": "Drugo",
      "tab01label10": "Stroškovno mesto",
      "tab01label11": "Termin (od/do):",
      "tab01label12": "Številka tečaja:",
      "tab01label13": "Cena:",
      "tab01label14": "Ponudnik:",
      "tab01label15": "Dodatek:",
      "tab01label16": "Izberite datoteko",
      "tab01label17": "Izbrana ni bila nobena datoteka...",
      "tab01label18": "Oddajte vlogo",
      "tab01label19": "Dodajte nadaljnje osebe",
      "tab01label20": "Prekinite",
      "olheadline": "Dodajte udeležence",
      "olheadline1": "Ime udeleženca",
      "olbodytext": "V nadaljevanju izberite imena nadaljnjih udeležencev.",
      "olerror": "Najden ni bil noben udeleženec. Prosimo, spremenite parametre iskanja."
    },
    "manage": {
      "headline": "Upravljanje vlog",
      "headlineInfo": "Povpraševanja sodelavcev",
      "bodytextInfo": "V nadaljevanju najdete vsa odprta povpraševanja za nadaljnje izobraževanje s prošnjo za obravnavo.",
      "newRequest": "Pošlji lastno vlogo",
      "tableHeadline01": "Prispelo",
      "tableHeadline02": "Predlagatelj",
      "tableHeadline03": "Tečaj št.",
      "tableHeadline04": "Naziv",
      "tableHeadline05": "Termin",
      "tableHeadline06": "Cena",
      "tableMark": "Označi vse",
      "buttonapprove": "Dodeli dovoljenje",
      "buttondecline": "Dodeli soglasje",
      "modal": {
        "refuse": {
          "headline": "Sporočilo - zavrnitev zahteve",
          "placeholder": "Prosimo, vpišite besedilo...",
          "checkbox": "Zavrnitev zahteve brez sporočila",
          "button": "Pošlji",
          "approval": "Dovoljenje dodeljeno",
          "refuse": "Soglasje ni dodeljeno"
        }
      }
    }
  }
}
