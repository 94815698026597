// Bulgarisch

module.exports = {
  "main_navigation": {
    "login": "Вход",
    "registration": "Регистрация",
    "courses": "Моите тренинги",
    "coursesDetail": "Детайли на тренинга",
    "catalog": "Каталог",
    "learningProfile": "Учебен профил",
    "contact": "Контакт",
    "profile": "Профил",
    "pwLost": "Забравени данни за вход?",
    "about": "Импресум",
    "terms_and_conditions": "Условия за ползване / Защита на данните",
    "logout": "Изход",
    "faq": "ЧЗВ",
    "trainingmanager": "Заявки",
    "trainingmanagerrequest": "Запитване",
    "trainingmanagermanage": "Управление",
    "trainingmanagermanagedetail": "Детайл"
  },
  "general": {
    'offline': {
      'headline': 'Вие сте офлайн!',
      'text': 'Това приложение се нуждае от активна интернет връзка, за да работи правилно.',
    },
    "year": "Година",
    "month": "Месец",
    "noResult": "Не е намерен резултат.",
    "months": {
      "10": "Октомври",
      "11": "Ноември",
      "12": "Декември",
      "01": "Януари",
      "02": "Февруари",
      "03": "Март",
      "04": "Април",
      "05": "Май",
      "06": "Юни",
      "07": "Юли",
      "08": "Август",
      "09": "Септември"
    },
    "category": "Категория",
    "calendar": "Календар",
    "search": "Търсене",
    "searchError": "Моля, въведете поне три символа.",
    "showLess": "Показване на по-малко",
    "showMore": "Показване на повече",
    'pagination': {
      'next': 'Още',
      'prev': 'Обратно',
      'separator': 'от'
    }
  },
  "maintenance": {
    "title": "Техническа поддръжка",
    "text": "Академията не е достъпна в момента поради техническа поддръжка. Моля, опитайте отново по-късно."
  },
  "password": {
    "requirements": {
      "title": "Политика за пароли",
      "minimalLength": "Дължината трябва да бъде минимум #0.",
      "mandantName": "Терминът \"# 0\" не трябва да се използва.",
      "names": "Паролата не трябва да съвпада с името или фамилията.",
      "userName": "Паролата не трябва да съдържа Вашите потребителски имена.",
      "userNameEqual": "Паролата не трябва да съдържа Вашите потребителски имена.",
      "bigLetters": "Трябва да има минимум #0 главни букви.",
      "smallLetters": "Трябва да има минимум #0 малки букви.",
      "digits": "Трябва да има минимум #0 число/а.",
      "sameLetters": "Не може да следват повече от #0 символа един след друг.",
      "oldPassword": "Не може да е старата парола.",
      "birthday": "Паролата не трябва да съответства на собствена дата на раждане.",
      "minSymbolsPolicy": "Трябва да има минимум #0 специални символа.",
      "minPasswordLength": "Паролата трябва да е с дължина минимум #0 символа.",
      "maxPasswordAttempts": "Остават Ви #0 опита да въведете паролата правилно."
    }
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'Are you sure you want to cancel this booking?'
    }
  },
  "pages": {
    "notFound": {
      "headline": "Съжаляваме!",
      "text1": "Заявената страница не е намерена",
      "text2": "Използвайте бутона за връщане на Вашия браузър или отидете на началната страница.",
      "button": "Обратно към началната страница"
    },
    "userNotFound": {
      "headline": "Възникна грешка при регистрацията Ви!",
      "text": "Потребителят на Вашата организация не може да бъде намерен. Моля, опитайте отново по-късно или се свържете с лицето за контакт или с Вашия ръководител.",
      "button": "Опитайте отново"
    },
    "courseCalendar": {
      "expired": "Изтекъл срок",
      "soonExpired": "Изтичащ скоро",
      "expiredMeetings": "Минали дати"
    },
    "profile": {
      "userdata": "Потребителски данни",
      "password": "Парола",
      "genderLab": "Обръщение",
      "gender": {
        "men": "Господин",
        "woman": "Госпожа",
        "diverse": "Друго",
        "unknown": "Без данни"
      },
      "firstname": "Име",
      "lastname": "Фамилия",
      "email": "Имейл",
      "strasse": "Улица / №",
      "city": "ПК / Населено място",
      "phone": "Телефон",
      "fax": "Факс",
      "position": "Позиция",
      "department": "Отдел",
      "required": "Задължително поле",
      "oldPassword": "Стара парола",
      "newPassword": "Нова парола",
      "newPasswordRep": "Повторение",
      "company": "Компания",
      "language": "Език"
    },
    "catalog": {
      "back": "Обратно",
      "bookingDialog": {
        "notice": "Въведете тук Вашето основание за запитване за тренинг"
      }
    },
    "contact": {
      "info": "Моля, въведете Вашето запитване в полето за съобщение. Ако имате предвид специален тренинг, моля, посочете го: Така Вашето съобщение може да бъде изпратено директно на правилното място.",
      "trainingSelection": "Избор на тренинг",
      "yourMessage": "Вашето съобщение",
      "success": "Вашето съобщение е изпратено успешно!",
      "otherReason": "Общо запитване за контакт",
      "otherReasonSubject": "Друго запитване",
      "chooseReason": "Моля, изберете"
    },
    "learningProfile": {
      "title": "Учебен профил",
      "unit": "Учебни единици",
      "points": "Общ брой точки",
      "history": "История на обучението",
      "restoreFilter": "Рестартиране на филтъра",
      "certificate": "Сертификат",
      "downloadAll": "Изтегляне на всички",
      "withoutCategory": "Без категория",
      "tooltip01": "Вие сте завършили",
      "tooltip02": "от",
      "tooltip03": "учебни единици.",
      "gamification": {
        "badge": {
          "title": "Отличия",
          "congratulations": "Поздравления!",
          "achieved": "Получихте ново отличие!"
        }
      }
    },
    "courseDetail": {
      "title": "Детайли на тренинга",
      "back": "Обратно към списъка",
      "progress": "Напредък",
      "expiry": "валиден до",
      "classroomNo": "Номер на обучението",
      "contactPerson": "Лице за контакт",
      "targetGroup": "Целева група",
      "bonusPoints": "Бонус точки",
      "classroomContent": "Съдържание на обучението",
      "webinarLight": "Информация за уебинара",
      "specials": "Особености",
      "stornoConditions": "Условия за анулиране",
      "meeting": "дата",
      "meetings": "Дати",
      'freeDay': 'Free day',
      'freeDayInfo': 'Свободните дни не са посочени по-горе\n',
      "speaker": "Инструктор / Референт",
      "location": "Място на обучението",
      "requested": "ЗАПИТВАНЕ",
      "waitlist": "ЛИСТА НА ЧАКАЩИТЕ",
      "fullyBooked": "РЕЗЕРВИРАНО",
      "edit": "Обработване",
      "webinarSpeaker": "Референт",
      "docsAndLinks": "Документи и линкове",
      "links": "Линкове",
      "webinar": {
        "host": "Откриване на уебинара",
        "state": {
          "ended": "Уебинарът приключи",
          "beforeStart": "Уебинарът започва на "
        }
      },
      "sidebar": {
        "price": "Цена за участник",
        "duration": "Продължителност на обучението",
        "to": "до",
        "numberOfParticipants": "Брой участници",
        "status": "Статус на резервацията",
        'totalStatus': 'Общо състояние',
        'contentStatus': 'Статус на обучението'
      },
      "bookingInformation": {
        "title": "Информация за резервацията",
        "note": "Забележка",
        "accounts": "Разходен център",
        "invoiceRecipient": "Получател на фактурата",
        "invoiceAddress": "Адрес за фактуриране",
        "food": "Предпочитание за хранене",
        "arrival": "Пристигане",
        "arrivalType": {
          "none": "Без данни",
          "car": "Автомобил",
          "train": "Влак",
          "plane": "Самолет"
        },
        "arrivalDate": "Дата на пристигане",
        "departure": "заминаване",
        "departureDate": "Дата на отпътуване",
        "flightNumber": "Номер на полет",
        "trainNumber": "номер на влака",
        "railwayStation": "Гара",
        'placeholder': {
          'departure_airport': 'Летище на заминаване',
          'destination_airport': 'Летище на дестинацията',
          'departure_trainstation': 'Станция на заминаване',
          'destination_trainstation': 'Станция на местоназначението',
          'departure_car': 'Място на отпътуване',
          'destination_car': 'Място на дестинацията',
          'from': 'от',
          'to': 'към'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          "Януари",
          "Февруари",
          "Март",
          "Април",
          "Май",
          "Юни",
          "Юли",
          "Август",
          "Септември",
          "Октомври",
          "Ноември",
          "Декември",
        ]
      },
      "reserve": {
        "title": "Не можете да настроите датата/ите?",
        "text": "Отбележете интереса си! След това ще бъдете информирани за възможни нови дати.",
        "button": "Отбелязване",
        "prebooked": {
          "text": "Вие отбелязахте интереса си за този тренинг."
        }
      },
      'downloads': {
        'modalHeadline': 'Изтегляне',
        'sidebarLink': 'Документ(и)',
      }
    },
    "course": {
      "type": {
        "0": "Онлайн тренинг",
        "1": "Онлайн тренинг",
        "2": "Онлайн тренинг",
        "3": "Онлайн тренинг",
        "4": "Онлайн тренинг",
        "5": "Онлайн тренинг",
        "6": "Присъствен тренинг",
        "7": "Уебинар",
        "-2": "Проект",
        "undefined": "-"
      },
      "status": {
        "open": "Отворено",
        'sent': 'Sent',
        "passed": "Издържан",
        'sent': 'Изпратено',
        "failed": "Не е издържан",
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        "expired": "Изтекъл срок",
        "booked": "Резервиран",
        "started": "В процес на обработване",
        "upcoming": "Предстоящ",
        "running": "Текущ",
        "completed": "Приключил",
        "notyet": "Все още не е наличен"
      },
      "notification": {
        "success1": "Вие завършихте тренинга ",
        "success2": "!"
      },
      'warning': {
        'headline': 'Внимание!',
        'body': {
          '1': 'Избрали сте различен език от последния път за това обучение.',
          '2': 'Ако продължите и кликнете върху "Започнете обучението", напредъкът се нулира.',
        },
        'buttons': {
          'start': 'Започнете обучението',
          'startPrevious': 'Избор на предишен език',
        }
      },
      "statusLabel": {
        "training": "Тренинг",
        'content': 'Content',
        'attendance': 'Attendance',
        "exam": "Изпит",
        "survey": "Проучване",
        "efficacyControl": "Контрол на ефективността"
      },
      "start": {
        "survey": "Начало на проучването",
        "exam": "Начало на изпита",
        "efficacyControl": "Стартиране на контрол на ефективността"
      },
      "prefix": {
        "category": "Категория",
        "state": "Статус",
        "type": "Тип на тренинга"
      },
      "noAssigned": "Не са Ви възложени тренинги.",
      "noSolved": "Все още не сте завършили никакъв тренинг. Започнете сега с първата си учебна единица в раздел „Моите тренинги“!",
      "noAssignedBookable": "Не са Ви възложени тренинги, които можете да резервирате.",
      "noResult": "Не са намерени тренинги, отговарящи на критериите.",
      "loadingFailed": "Заявените записи с данни не могат да бъдат заредени поради неизвестна грешка!",
      "noResultInCategory": "Все още не са зададени тренинги към категорията.",
      "meeting": {
        "noMeeting": "няма дата",
        "multipleMeetings": "разл. дати"
      },
      "project": {
        "courses": "Тренинги"
      }
    },
    "login": {
      "invalidUsername": "Невалидно влизане. Грешно потребителско име!",
      "invalidUser": "Невалидно влизане. Грешно потребителско име / парола!",
      "invalidPassword": "Невалидно влизане. Остават Ви #0 опита да въведете паролата правилно.",
      "accountBlocked": "Вашият профил е блокиран. Моля, свържете се с отговорен служител.",
      "error": "Грешно потребителско име и/или парола!",
      "empty": "Моля, попълнете всички полета.",
      "back": "Обратно към вход",
      "description": "Моля, въведете Вашето потребителско име или Вашия имейл адрес за повторно изпращане на данните за достъп.",
      "password-reset": {
        "description": "Моля, изберете нова парола за Вашия потребителски профил.",
        "success": "Вашата парола беше променена успешно.",
        "error": "Посочените пароли не съвпадат."
      },
      "placeholder": " Потребителско име / Имейл адрес",
      "button": "Заявяване на данни за достъп",
      "success": "Запитването е изпратено успешно.",
      "errorForgot": "Не беше намерен потребител, отговарящ на посочените критерии.",
      "errorForgotSecurityQuestion": "За съжаление отговорът не е правилен!"
    },
    "registration": {
      "description": "Ако все още нямате данни за вход, можете да се регистрирате, като използвате промоционалния код на Вашата компания. След това незабавно ще получите имейл.",
      "actionCodeError": "Въведеният регистрационен код е невалиден",
      "actionCodeLimitExceeded": "Максималният брой възможни регистрации за въведения промоционален код е изразходван.",
      "registerDescription": "Моля, попълнете формуляра по-долу. Данните за достъп ще Ви бъдат изпратени по имейл след няколко минути.",
      "title": "Вашата регистрация в Академията",
      "required": "Задължително поле*",
      "emailError": "Посоченият от Вас имейл адрес вече е използван.",
      "emailErrorNotAllowed": "Не е възможна регистрация с този имейл адрес.",
      "send": "Изпращане",
      "completeMessage": "Вие се регистрирахте успешно в Академията!"
    },
    "dataProtection": {
      "accept": "Съгласие",
      "acceptText": "Да, съгласен съм с Условия за ползване / Защита на данните"
    },
    "securityQuestion": {
      "title": "Въпрос за сигурност",
      "chooseQuestion": "Моля, изберете",
      "questionSelection": "Моля, изберете въпрос",
      "yourAnswer": "Моля, въведете Вашия отговор",
      "description": "Моля, отговорете на въпроса за сигурност и изберете нова парола за Вашия потребителски профил."
    },
    "faq": {
      "title": "Често задавани въпроси"
    }
  },
  "sidebar": {
    "all": "Всички",
    "categories": "КАТЕГОРИИ",
    "status": "СТАТУС",
    "courseType": "ТИП НА ТРЕНИНГА",
    "topics": "ТЕМАТИЧНИ ОБЛАСТИ"
  },
  "placeholder": {
    "username": "Потребителско име",
    "password": "Парола",
    "actionCode": "Промоционален код"
  },
  "button": {
    "login": "РЕГИСТРАЦИЯ",
    "login-sso": "ВЛИЗАНЕ В ACTIVE-DIRECTORY",
    "send": "ИЗПРАЩАНЕ",
    "start_course": "СТАРТ",
    "changePassword": "Промяна на паролата",
    "reset": "Рестартиране",
    "save": "Съхраняване",
    "request": "Запитване",
    "storno": "Отмяна",
    "canceled": "Отменена дата",
    "close": "Затваряне",
    "booking": "Резервиране",
    "waitlist": "Листа на чакащите",
    "start_webinar": "Начало на уебинара",
    "open_webinar": "Уебинар отваряне",
    'cancel': 'Отмяна',
    'confirm': 'Потвърдете'
  },
  "bookingState": {
    "0": "ЗАПИТВАНЕ",
    "1": "РЕЗЕРВИРАН",
    "2": "ЛИСТА НА ЧАКАЩИТЕ",
    "3": "ОТМЕНЕН",
    "4": "ОТМЕНЕН",
    "5": "ОТКАЗАН",
    "6": "ОТБЕЛЯЗАН",
    "onlineTraining": {
      "0": "ЗАПИТВАНЕ",
      "1": "РЕЗЕРВИРАН",
      "2": "ОТКАЗАН"
    }
  },
  "foodPreference": {
    "none": "Няма",
    "lactoseIntolerance": "Непоносимост към лактоза",
    "vegetarian": "Вегетарианска храна",
    "vegan": "Веган",
    "nutAllergy": "Алергия към ядки",
    "diabetic": "Диабет",
    "glutenFree": "Без глутен",
    "kosher": "Кашер",
    "halal": "Халал",
    "other": "Други"
  },
  "notifications": {
    "error": "Нещо се обърка!",
    "errors": {
      "missingInput": "Моля, попълнете полетата",
      "imageLoad": "Заявеното изображение не може да бъде заредено"
    },
    "success": {
      "book": {
        "meeting": "Вие резервирахте успешно присъствения тренинг.",
        "webinar": "Вие резервирахте успешно уебинара.",
        "course": "Вие резервирахте успешно тренинга.",
        "project": "Вие резервирахте успешно проекта."
      },
      "request": {
        "meeting": "Вие изпратихте успешно запитване за присъствения тренинг.",
        "webinar": "Вие изпратихте успешно запитване за уебинара.",
        "course": "Вие изпратихте успешно запитване за тренинга.",
        "project": "Вие изпратихте успешно запитване за проекта."
      },
      "profile": {
        "updateSuccess": "Вие актуализирахте успешно профила си!",
        "updatePasswordSuccess": "Вие актуализирахте успешно паролата си!"
      },
      "securityQuestion": {
        "questionSave": "Вашият въпрос за сигурност е съхранен!"
      }
    }
  },
  "webservice": {
    "error": {
      "duplicate": "Резервацията съществува вече!",
      "noaccess": "Достъпът не е разрешен.",
      "notimplementet": "В момента функцията не е налична."
    },
    "success": {
      "booking": "Вие направихте успешно резервацията.",
      "request": "Вие направихте успешно запитването.",
      "cancel": "Вие направихте успешно отмяната. ",
      "waitlist": {
        "add": "Вие сте включени в списъка на чакащите"
      },
      "reservelist": {
        "add": "Вие сте включени в списъка за отбелязване"
      }
    },
    "courseCalendar": {
      "noDate": "Без краен срок"
    }
  },
  "trainingmanager": {
    "headline": "Заявка за квалификационно обучение",
    "headlinerequest": "Подаване на заявление",
    "bottonrequest": "Подаване на заявление",
    "buttonmanage": "Управление на заявленията",
    "requestform": {
      "requester": "Кандидат:",
      "date": "Дата:",
      "tab01": "Подаване на искане",
      "tab02": "Искане за група/трети лица",
      "tab01headline01": "Име на участника:",
      "tab01headline02": "Тема на квалификационното обучение:",
      "tab01headline04": "Имена на участниците:",
      "tab01headline03": "Детайли на курса:",
      "tab01label01": "Обръщение:",
      "tab01label02": "Име:",
      "tab01label03": "Фамилия:",
      "tab01label04": "Господин",
      "tab01label05": "Госпожа",
      "tab01label06": "Категория:",
      "tab01label07": "Титла*:",
      "tab01label08": "Кратко описание:",
      "tab01label09": "Други:",
      "tab01label09O": "Други",
      "tab01label10": "Разходен център:",
      "tab01label11": "Дата (от/до):*",
      "tab01label12": "Номер на курса:",
      "tab01label13": "Цена:",
      "tab01label14": "Доставчик:",
      "tab01label15": "Приложение:",
      "tab01label16": "Избиране на файл",
      "tab01label17": "Не е избран файл...",
      "tab01label18": "Изпращане на заявлението",
      "tab01label19": "Добавяне на друго лице",
      "tab01label20": "Прекъсване",
      "olheadline": "Добавяне на участник",
      "olheadline1": "Име на участника",
      "olbodytext": "Изберете по-долу имената на другите участници в тренинга.",
      "olerror": "Не са намерени участници. Моля, променете Вашите параметри на търсене."
    },
    "manage": {
      "headline": "Управление на заявленията",
      "headlineInfo": "Запитвания на служители",
      "bodytextInfo": "По-долу ще намерите всички отворени запитвания за квалификационни обучения със заявката за обработка.",
      "newRequest": "Изпращане на собствено заявление",
      "tableHeadline01": "Получаване",
      "tableHeadline02": "Кандидат",
      "tableHeadline03": "Курс №",
      "tableHeadline04": "Титла",
      "tableHeadline05": "Дата",
      "tableHeadline06": "Цена",
      "tableMark": "Изтегляне на всички",
      "buttonapprove": "Даване на разрешение",
      "buttondecline": "Не се одобрява",
      "modal": {
        "refuse": {
          "headline": "Съобщение - отхвърляне на искане",
          "bodytext": "",
          "placeholder": "Моля, въведете текст....",
          "checkbox": "Искането е отхвърлено без съобщение",
          "button": "Изпращане",
          "approval": "Дадено разрешение",
          "refuse": "Не е дадено одобрение"
        }
      }
    }
  }
}
