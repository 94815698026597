// Englisch

module.exports = {
  'main_navigation': {
    'login': 'Login',
    'registration': 'Registration',
    'courses': 'My Training',
    'coursesDetail': 'Training details',
    'noResult': 'No result was found.',
    'catalog': 'Catalog',
    'learningProfile': 'Learning Profile',
    'contact': 'Contact',
    'profile': 'Profile',
    'pwLost': 'Forgot login data?',
    'about': 'Imprint',
    'terms_and_conditions': 'Terms of use/Data protection',
    'logout': 'Logout',
    'faq': 'FAQ',
    'trainingmanager': 'Requests',
    'trainingmanagerrequest': 'Request',
    'trainingmanagermanage': 'Manage', // ?
    'trainingmanagermanagedetail': 'Detail' // ?
  },
  'general': {
    'offline': {
      'headline': 'You are offline!',
      'text': 'This app needs an active internet connection to work properly.',
    },
    'year': 'Year',
    'month': 'Month',
    'months': {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      '10': 'October',
      '11': 'November',
      '12': 'December'
    },
    'category': 'Category',
    'calendar': 'Calendar',
    'search': 'Search',
    "searchError": "Please enter at least three characters.",
    'showLess': 'Show less',
    'showMore': 'Show more',
    'pagination': {
      'next': 'Next',
      'prev': 'Previous',
      'separator': 'of',
      'pageSize': 'Items per page'
    }
  },
  'maintenance': {
    'title': 'Maintenance',
    'text': 'Due to maintenance work, the Academy is currently unavailable. Please try again later.',
  },
  'password': {
    'requirements': {
      'title': 'Password Policies',
      'minimalLength': 'A password length of minimum #0 characters is required.',
      'mandantName': 'The term "#0" may not be used.',
      'names': 'First and/or last name may not be used.',
      'userName': 'May not contain your username',
      'userNameEqual': 'May not contain your username.',
      'bigLetters': 'At least #0 upper case letter(s) is required.',
      'smallLetters': 'At least #0 lower case letter(s) is required.',
      'digits': 'At least #0 digit(s) is required.',
      'sameLetters': 'Do not use the same character more than #0 times in a row.',
      'oldPassword': 'It may not be the old password.',
      'birthday': 'The password may not match your own date of birth.',
      'minSymbolsPolicy': 'At least #0 symbol(s) required.',
      'minPasswordLength': 'A password length of minimum #0 characters is required.',
      'maxPasswordAttempts': 'You have #0 more attempts to enter the password correctly.'
    }
  },
  'pages': {
    'bossBooking': {
      'title': 'Booking request',
      'subTitle': {
        'accept': 'Do you want to accept the booking request?',
        'decline': 'Do you want to decline the booking request?',
      },
      'user': 'User',
      'training': 'Training',
      'meeting': 'Appointment',
      'time': 'Time',
      'location': 'Location',
      'project': 'Project',
      'reason': 'Optionally enter a reason here....',
      'accept': 'Accept',
      'decline': 'Decline',
      'home': 'Home page',
      'result': {
        'accept': 'The booking request has been accepted.',
        'decline': 'The booking request was rejected.',
        'error': 'The booking request does not exist or has already been completed.',
        'conflict': 'The booking request could not be accepted because the appointment is already fully booked.'
      }
    },
    'booking': {
      'cancel': {
        'title': 'Cancel booking',
        'confirm': 'Are you sure you want to cancel this booking?'
      }
    },
    'notFound': {
      'headline': 'We are sorry!',
      'text1': 'The requested page could not be found.',
      'text2': 'Use your browsers back button or go back to the homepage.',
      'button': 'Back to homepage'
    },
    'userNotFound': {
      'headline': 'An error occurred during your login!',
      'text': 'Your organisation user could not be found. Please try again at a later time or contact a responsible person or supervisor.',
      'button': 'Try again'
    },
    'courseCalendar': {
      'expired': 'Expired',
      'soonExpired': 'Expiring soon',
      'expiredMeetings': 'Past appointments'
    },
    'profile': {
      'userdata': 'User information',
      'password': 'Password',
      'genderLab': 'Salutation',
      'gender': {
        'men': 'Mr',
        'woman': 'Ms',
        'diverse': 'Diverse',
        'unknown': 'Not specified'
      },
      'firstname': 'First name',
      'lastname': 'Last name',
      'email': 'Email',
      'strasse': 'Street, Nr.',
      'city': 'ZIP / City',
      'phone': 'Phone',
      'fax': 'Fax',
      'position': 'Position',
      'department': 'Department',
      'required': 'Required field',
      'oldPassword': 'Old password',
      'newPassword': 'New password',
      'newPasswordRep': 'Repeat password',
      'company': 'Company',
      'language': 'Language'
    },
    'catalog': {
      'back': 'Back',
      'bookingDialog': {
        'notice': 'Please fill in your explanation for the order request'
      }
    },
    'contact': {
      'info': 'Please enter your request in the message field. If you refer to a training, please specify it. Your message can then be forwarded directly to the right place..',
      'trainingSelection': 'Training selection',
      'yourMessage': 'Your message',
      'success': 'Your message has been sent successfully!',
      'otherReason': 'General contact request',
      'otherReasonSubject': 'Other request',
      'chooseReason': 'Please choose'
    },
    'learningProfile': {
      'title': 'Learning profile',
      'unit': 'Learning units',
      'points': 'Total points',
      'history': 'Learning history',
      'restoreFilter': 'Reset filter',
      'certificate': 'Certificate',
      'downloadAll': 'Download All',
      'withoutCategory': 'Without category',
      'tooltip01': 'You have',
      'tooltip02': 'of',
      'tooltip03': 'learning units completed.',
      'gamification': {
        'badge':{
          'title': 'Awards',
          'congratulations': 'Congratulations!',
          'achieved': 'You have received a new award!'
        }
      }
    },
    'courseDetail': {
      'noMeeting': 'No appointment has been created yet for this training or all appointments have already expired.',
      'title': 'Training details',
      'back': 'Back to training list',
      'progress': 'Progress',
      'expiry': 'valid until',
      'classroomNo': 'Training number',
      'contactPerson': 'Contact person',
      'targetGroup': 'Target group',
      'bonusPoints': 'Bonus points',
      'classroomContent': 'Training contents',
      'webinarLight': 'Webinar information',
      'specials': 'Particularities',
      'stornoConditions': 'Cancellation policy',
      'meeting': 'Appointment',
      'meetings': 'Dates',
      'freeDay': 'Free day',
      'freeDayInfo': 'Free days are not listed above\n',
      'speaker': 'Trainer / Speaker',
      'location': 'Venue',
      'requested': 'REQUESTED',
      'waitlist': 'WAITING LIST',
      'fullyBooked': 'FULLY BOOKED',
      'edit': 'Edit',
      'webinarSpeaker': 'Speaker',
      'docsAndLinks': 'Documents and Links',
      'links': 'Links',
      'deadline': 'Deadline',
      'stornoDeadline': 'Deadline',
      'webinar': {
        'host': 'Open Webinar',
        'state': {
          'ended': 'Webinar ended',
          'beforeStart': 'Webinar starts at '
        }
      },
      'sidebar': {
        'price': 'Price',
        'duration': 'Learning time',
        'to': 'to',
        'numberOfParticipants': 'Availability',
        'status': 'Booking status',
        'totalStatus': 'Summary',
        'contentStatus': 'Training status'
      },
      'bookingInformation': {
        'title': 'Booking information',
        'note': 'Note',
        'accounts': 'Cost center',
        'invoiceRecipient': 'Invoice recipient',
        'invoiceAddress': 'Invoice address',
        'food': 'Food preference',
        'arrival': 'Arrival',
        'arrivalType': {
          'none': 'Not specified',
          'car': 'Car',
          'train': 'Train',
          'plane': 'Airplane'
        },
        'arrivalDate': 'Arrival date',
        'departure': 'Departure',
        'departureDate': 'Departure date',
        'flightNumber': 'Flight number',
        'trainNumber': 'Train number',
        'railwayStation': 'Railway station',
        'placeholder': {
          'departure_airport': 'Departure airport',
          'destination_airport': 'Destination airport',
          'departure_trainstation': 'Departure station',
          'destination_trainstation': 'Destination station',
          'departure_car': 'Departure place',
          'destination_car': 'Destination place',
          'from': 'from',
          'to': 'to'
        },
        'weekdays': [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ],
        'months': [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]
      },
      'reserve': {
        'title': 'You cannot attend the appointment(s)?',
        'text': 'Make a note of your interest! You will then be informed about possible new dates.',
        'button': 'Reserve',
        'prebooked': {
          'text': 'Your reservation for this event/training has been noted.'
        },
      },
      'downloads': {
        'modalHeadline': 'Downloads',
        'sidebarLink': 'Document(s)',
      }
    },
    'course': {
      'type': {
        '0': 'Online Training',
        '1': 'Online Training',
        '2': 'Online Training',
        '3': 'Online Training',
        '4': 'Online Training',
        '5': 'Online Training',
        '6': 'Classroom training',
        '7': 'Webinar',
        '-2': 'Project',
        'undefined': '-'
      },
      'status': {
        'open': 'Open',
        'sent': 'Sent',
        'passed': 'Passed',
        'failed': 'Failed',
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        'expired': 'Expired',
        'booked': 'Booked',
        'started': 'In progress',
        'upcoming': 'Upcoming',
        'running': 'Running',
        'completed': 'Completed',
        'notyet': 'Not yet available'
      },
      'notification': {
        'success1': 'You have completed the training ',
        'success2': ' successfully!',
      },
      'warning': {
        'headline': 'Attention!',
        'body': {
          '1': 'You have selected a different language than the last time for this training.',
          '2': 'If you proceed and click on ‘Start training’ the progress is being reset.',
        },
        'buttons': {
          'start': 'Start training',
          'startPrevious': 'Select previous language',
        }
      },
      'statusLabel': {
        'training': 'Training',
        'content': 'Content',
        'attendance': 'Attendance',
        'exam': 'Exam',
        'survey': 'Feedback',
        'efficacyControl' : 'Effi.-control'
      },
      'start': {
        'survey': 'Start survey',
        'exam': 'Start exam',
        'efficacyControl':'Start efficacy control'
      },
      'prefix':{
        'category' : 'Category',
        'state' : 'Status',
        'type' : 'Training type'
      },
      'noAssigned': 'There are no training units assigned to you.',
      'noSolved': 'You have not yet completed any training. Start now with your first learning unit in the "My training" area!',
      'noAssignedBookable': 'There are no bookable training units assigned to you.',
      'noResult': 'No training units matching your criteria were found.',
      'loadingFailed': 'The requested data could not be loaded due to an an unknown error!',
      'noResultInCategory': 'No training units are assigned to this category.',
      'meeting': {
        'noMeeting': 'no date',
        'multipleMeetings': 'Various dates'
      },
      'project': {
        'courses': 'Trainings'
      }
    },
    'login': {
      'invalidUser': 'Invalid login data. Username / Password is wrong!',
      'invalidPassword': 'Invalid login data. You have #0 more attempts to enter the password correctly.',
      'accountBlocked': 'Your account has been blocked, please contact a responsible employee.',
      'error': 'Username and / or password incorrect!',
      'empty': 'Please fill in all required fields.',
      'back': 'Back to login',
      'description': 'Please enter your user name or email address to receive your login data.',
      'password-reset': {
        'description': 'Please select a new password for your user account.',
        'success': 'Your password has been changed successfully.',
        'error': 'The passwords do not match.'
      },
      'placeholder': ' User name / Email address',
      'button': 'Request login data',
      'success': 'Request has been sent successfully.',
      'errorForgot': 'No user matching the criteria was found!',
      'errorForgotSecurityQuestion': 'The answer that you entered is not correct!'
    },
    'registration': {
      'description': 'If you do not have a account yet, you can simply register via the promotion code of your company. Afterwards you will receive an e-mail immediately.',
      'actionCodeError': 'Invalid registration code',
      'actionCodeLimitExceeded': 'The maximum number of possible registrations for the entered registration code is exceeded.',
      'registerDescription': 'Please fill in the form. You will receive your login data via email shortly.',
      'title': 'Registration',
      'required': 'Required field *',
      'emailError': 'The e-mail address you entered is already in use.',
      'emailErrorNotAllowed': 'Registration is not allowed with your e-mail address.',
      'send': 'Submit',
      'completeMessage': 'You have registered successfully!'
    },
    'dataProtection': {
      'accept': 'Agree',
      'acceptText': 'Yes, I agree to the term of use/Data protection.'
    },
    'securityQuestion': {
      'title': 'Security question',
      'chooseQuestion': 'Please choose',
      'questionSelection': 'Please select one question',
      'yourAnswer': 'Please enter your answer',
      'description': 'Please reply the security question and select a new password for your user account.'
    },
    'faq': {
      'title': 'Frequently asked questions'
    }
  },
  'sidebar': {
    'all': 'All',
    'categories': 'CATEGORIES',
    'status': 'STATUS',
    'courseType': 'TRAINING TYPE',
    'topics': 'SUBJECT AREAS'
  },
  'placeholder': {
    'username': 'User name',
    'password': 'Password',
    'actionCode': 'Promotion code'
  },
  'button': {
    'login': 'SIGN IN',
    'login-sso': 'SINGLE-SIGN-ON LOGIN',
    'send': 'SEND',
    'start_course': 'START',
    'changePassword': 'Change Password',
    'reset': 'Reset',
    'save': 'Save',
    'request': 'Inquiry',
    'storno': 'Cancel',
    'canceled': 'Appointment canceled',
    'close': 'Close',
    'booking': 'Book',
    'waitlist': 'Waitlist',
    'start_webinar': 'Start Webinar',
    'open_webinar': 'Open Webinar',
    'cancel': 'Cancel',
    'confirm': 'Proceed'
  },
  'bookingState': {
    '0': 'REQUESTED',
    '1': 'BOOKED',
    '2': 'WAITING LIST',
    '3': 'CANCELED',
    '4': 'CANCELED',
    '5': 'DECLINED',
    '6': 'DATE SAVED',
    'onlineTraining': {
      '0': 'REQUESTED',
      '1': 'BOOKED',
      '2': 'DECLINED'
    }
  },
  'foodPreference': {
    'none': 'None',
    'lactoseIntolerance': 'Lactose intolerance',
    'vegetarian': 'Vegetarian',
    'vegan': 'Vegan',
    'nutAllergy': 'Nut allergy',
    'diabetic': 'Diabetes',
    'glutenFree': 'Gluten free',
    'kosher': 'Kosher',
    'halal': 'Halal',
    'other': 'Other',
  },
  'notifications': {
    'error': 'Something went wrong!',
    'errors': {
      'missingInput': 'Please fill in the fields',
      'imageLoad': 'The requested image could not be loaded.'
    },
    'success': {
      'book': {
        'meeting': 'You have successfully booked the classroom training.',
        'webinar': 'You have successfully booked the Webinar.',
        'course': 'You have successfully booked the training.',
        'project': 'You have successfully booked the project.'
      },
      'request': {
        'meeting': 'You have successfully sent a booking request for the classroom training.',
        'webinar': 'You have successfully sent a booking request for the Webinar.',
        'course': 'You have successfully sent a booking request for the training.',
        'project': 'You have successfully sent a booking request for the project.'
      },
      'profile': {
        'updateSuccess': 'You updated your profile successfully!',
        'updatePasswordSuccess': 'You updated your password successfully!'
      },
      'securityQuestion': {
        'questionSave': 'Your security question was saved!'
      }
    }
  },
  'webservice': {
    'error': {
      'duplicate': 'The booking already exists!',
      'noaccess': 'Access not allowed.',
      'notimplementet': 'Function is not available at the moment.'
    },
    'success': {
      'booking': 'You have successfully booked.',
      'request': 'You have successfully sent a booking request.',
      'cancel': 'You have successfully canceled. ',
      'waitlist': {
        'add': 'You have been wait listed'
      },
      'reservelist':{
        'add': 'You have been listed'
      }
    },
    'courseCalendar': {
      'noDate': 'Without deadline'
    }
  },
  'trainingmanager': {
    'headline': 'Request submission',
    'headlinerequest': 'Submit request',
    'bottonrequest': 'Submit request',
    'buttonmanage': 'Manage requests',
    'requestform': {
      'requester': 'Requestor:',
      'date': 'Date:',
      'tab01': 'Submit request',
      'tab02': 'Group / third party request',
      'tab01headline01': 'Name of participant:',
      'tab01headline02': 'Training topic:',
      'tab01headline04': 'Names of participants:',
      'tab01headline03': 'Course details:',
      'tab01label01': 'Salutation:',
      'tab01label02': 'First name:',
      'tab01label03': 'Last name:',
      'tab01label04': 'Mr',
      'tab01label05': 'Ms',
      'tab01label06': 'Category:',
      'tab01label07': 'Title*:',
      'tab01label08': 'Short description:',
      'tab01label09': 'Other:',
      'tab01label09O': 'Other',
      'tab01label10': 'Cost center:',
      'tab01label11': 'Date (from/to):*',
      'tab01label12': 'Course number:',
      'tab01label13': 'Price:',
      'tab01label14': 'Provider:',
      'tab01label15': 'Attachment:',
      'tab01label16': 'Select file',
      'tab01label17': 'No file selected...',
      'tab01label18': 'Submit request',
      'tab01label19': 'Add person',
      'tab01label20': 'Cancel',
      'olheadline': 'Add participant',
      'olheadline1': 'Name of participant',
      'olbodytext': 'Please select the other course participants below.',
      'olerror': 'No participants found. Please change your search parameters.',
    },
    'manage': {
      'headline': 'Manage requests',
      'headlineInfo': 'Team requests ',
      'bodytextInfo': 'Below you will find all open training requests with the request for processing.',
      'newRequest': 'Submit own request',
      'tableHeadline01': 'Eingang',
      'tableHeadline02': 'Requestor',
      'tableHeadline03': 'Course number',
      'tableHeadline04': 'Title',
      'tableHeadline05': 'Date',
      'tableHeadline06': 'Price',
      'tableMark': 'Select all',
      'buttonapprove': 'Approve',
      'buttondecline': 'Decline',
      'modal': {
        'refuse': {
          'headline': 'Message - Decline request',
          'bodytext': '',
          'placeholder': 'Please enter text…',
          'checkbox': 'Decline without message',
          'button': 'Submit',
          'approval': 'Request approved',
          'refuse': 'Request declined'
        }
      }
    }
  }
};
