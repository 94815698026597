// Koreanisch

module.exports = {
  'main_navigation': {
    'registration': '등록',
    'courses': '나의 트레이닝',
    'coursesDetail': '트레이닝 세부사항',
    'catalog': '목록',
    'learningProfile': '훈련 개요',
    'contact': '연락처',
    'profile': '프로필',
    'login': '로그인',
    'pwLost': 'ID를 잊어버리셨나요?',
    'about': '각인',
    'terms_and_conditions': '이용약관',
    'logout': '로그아웃',
    'trainingmanager': '요청',
    'trainingmanagerrequest': '요청',
    'trainingmanagermanage': '관리 ',
    'trainingmanagermanagedetail': '세부사항',
    'faq': '자주하는 질문'
  },
  'general': {
    'offline': {
      'headline': '너는 지금 접속이 안되있어!',
      'text': '이 앱이 제대로 작동하려면 활성 인터넷 연결이 필요합니다.',
    },
    'year': '년',
    'month': '월',
    'months': {
      '10': '10월',
      '11': '11월',
      '12': '12월',
      '01': '1월',
      '02': '2월',
      '03': '3월',
      '04': '4월',
      '05': '5월',
      '06': '6월',
      '07': '7월',
      '08': '8월',
      '09': '9월'
    },
    'category': '카테고리',
    'calendar': '달력',
    'search': '찾다. 목표물 탐색',
    "searchError": "3자 이상 입력해 주세요.",
    'showLess': '덜 보기',
    'showMore': '더 보기',
    'pagination': {
      'next': '다음',
      'prev': '이전의',
      'separator': '/',
      'pageSize': '페이지 당 항목',
    }
  },
  'maintenance': {
    'title': '유지보수 ',
    'text': '유지보수 관계로, 아카데미는 현재 이용 불가능합니다. 추후 다시 신청해주세요.'
  },
  'booking': {
    'cancel': {
      'title': 'Cancel booking',
      'confirm': 'Are you sure you want to cancel this booking?'
    }
  },
  'pages': {
    'notFound': {
      'headline': '유감입니다!',
      'text1': '요청하신 페이지를 찾지 못했습니다.',
      'text2': '브로우저의 돌아 가기 보턴을 이용하거나 시작 페이지로 가십시오.',
      'button': '시작 페이지로 돌아 가기'
    },
    'courseCalendar': {
      'expired': '만료',
      'soonExpired': '곧 만료됨',
      'expiredMeetings': '지난 약속'
    },
    'profile': {
      'userdata': '사용자 정보',
      'password': '비밀번호',
      'genderLab': '인사',
      'gender': {
        'men': 'Mr.',
        'woman': 'Ms.',
        'diverse': '다양한',
        'unknown': '확인되지 않음'
      },
      'firstname': '이름',
      'lastname': '성',
      'email': '이메일',
      'strasse': '길',
      'city': '시',
      'phone': '전화',
      'fax': '팩스',
      'position': '직책',
      'department': '부서',
      'required': '필수 입력',
      'oldPassword': '이전 비밀번호',
      'newPassword': '새로운 비밀번호',
      'newPasswordRep': '비밀번호 재입력',
      'company': '회사',
      'language': '언어'
    },
    'catalog': {
      'back': '뒤로',
      'bookingDialog': {
        'notice': '주문 요청을 위한 설명을 기재해주세요.'
      }
    },
    'contact': {
      'info': '메시지란에 요청사항을 기재해주세요. 트레이닝 관련 요청 사항이 있으시면, 적어주세요. 요청 사항은 관련 부서로 전달됩니다.',
      'trainingSelection': '트레이닝 선택',
      'yourMessage': '당신의 메시지',
      'success': '당신의 메시지가 전송되었습니다.',
      'otherReason': '연락 요청',
      'otherReasonSubject': '다른 요청',
      'chooseReason': '선택해 주십시오'
    },
    'learningProfile': {
      'title': '학습 프로필',
      'unit': '학습 유닛',
      'points': '총 점수',
      'history': '학습 내역',
      'restoreFilter': '필터 초기화',
      'certificate': '인증',
      'downloadAll': '모두 다운로드',
      'withoutCategory': '카테고리 없음',
      'tooltip01': '귀하가 가지고 있는 것은',
      'tooltip02': '의',
      'tooltip03': '유닛 학습 완료.',
      'gamification': {
        'badge':{
          'title': '수상',
          'congratulations': '축하합니다!',
          'achieved': '새로운 상을 받았습니다!'
        }
      }
    },
    'courseDetail': {
      'title': '트레이닝 세부 사항',
      'back': '트레이닝 목록으로 돌아가기',
      'progress': '진행 사항 ',
      'expiry': '까지 유효',
      'classroomNo': '트레이닝 수',
      'contactPerson': '문의 담당자',
      'targetGroup': '타깃 그룹',
      'bonusPoints': '보너스 점수',
      'classroomContent': '트레이닝 내용',
      'webinarLight': '웨비나 정보',
      'specials': '자세한 요소',
      'stornoConditions': '취소 정책',
      'meeting': '일자',
      'meetings': '일자',
      'freeDay': 'Free day',
      'freeDayInfo': '무료 날짜는 위에 나열되지 않습니다.\n',
      'speaker': '트레이너 / 발표자',
      'location': '트레이닝 장소',
      'requested': '요청됨',
      'waitlist': '대기 명단',
      'fullyBooked': '예약 불가능',
      'edit': '편집',
      'webinarSpeaker': '발표자',
      'docsAndLinks': '문서 및 링크',
      'links': '웹 링크',
      'webinar': {
        'host': '웨비나 열기',
        'state': {
          'ended': '웨비나 종료',
          'beforeStart': '웨비나 시작: '
        }
      },
      'sidebar': {
        'price': '가격',
        'duration': '학습 시간',
        'to': '으로, 에게',
        'numberOfParticipants': '참가자 수',
        'status': '예약 상태',
        'totalStatus': '전반적인 상태',
        'contentStatus': '훈련 상태'
      },
      'bookingInformation': {
        'title': '예약 정보',
        'note': '비고',
        'accounts': '비용 센터 ',
        'invoiceRecipient': '인보이스 수령자',
        'invoiceAddress': '청구 주소',
        'food': '선호 메뉴',
        'arrival': '도착',
        'arrivalType': {
          'none': '확인되지 않음',
          'car': '차량',
          'train': '열차',
          'plane': '비행기'
        },
        'arrivalDate': '도착일',
        'departure': '출발',
        'departureDate': '출발일',
        'flightNumber': '항공기 번호',
        'trainNumber': '기차 번호',
        'railwayStation': '기차역',
        'placeholder': {
          'departure_airport': '출발 공항',
          'destination_airport': '목적지 공항',
          'departure_trainstation': '출발역',
          'destination_trainstation': '목적지 기차역',
          'departure_car': '출발지',
          'destination_car': '목표 위치',
          'from': '~에서',
          'to': '~까지'
        },
        'weekdays': [
          '월요일',
          '화요일',
          '수요일',
          '목요일',
          '금요일',
          '토요일',
          '일요일'
        ],
        'months': [
          '1월',
          '2월',
          '3월',
          '4월',
          '5월',
          '6월',
          '7월',
          '8월',
          '9월',
          '10월',
          '11월',
          '12월'
        ]
      },
      'reserve': {
        'title': '약속에 참석하지 못하십니까?',
        'text': '관심사를 메모하세요! 가능한 새 일자의 알림을 받게 됩니다.',
        'button': '예약',
        'prebooked': {
          'text': '이번 이벤트/트레이닝 예약이 기록되었습니다.'
        }
      },
      'downloads': {
        'modalHeadline': '다운로드',
        'sidebarLink': '서류',
      }
    },
    'course': {
      'type': {
        '0': '온라인 트레이닝',
        '1': '온라인 트레이닝',
        '2': '온라인 트레이닝',
        '3': '온라인 트레이닝',
        '4': '온라인 트레이닝',
        '5': '온라인 트레이닝',
        '6': '클래스룸 트레이닝',
        '7': '웨비나',
        '-2': '프로젝트',
        'undefined': '-'
      },
      'status': {
        'open': '열기',
        'sent': 'Sent',
        'passed': '통과',
        'sent': '전송된',
        'failed': '실패',
        'participated': 'Participated',
        'notParticipated': 'Not participated',
        'expired': '만료됨',
        'booked': '예약됨',
        'started': '진행중',
        'upcoming': '예정',
        'running': '작동중',
        'completed': '완료'
      },
      'notification': {
        'success1': '트레이닝을 완료하셨습니다. ',
        'success2': ' 성공!'
      },
      'warning': {
        'headline': '주목!',
        'body': {
          '1': '이 교육에 대한 마지막 시간과 다른 언어를 선택했습니다.',
          '2': '계속 진행하고 \'교육 시작\'을 클릭하면 진행 상황이 재설정됩니다.',
        },
        'buttons': {
          'start': '교육 시작',
          'startPrevious': '이전 언어 선택',
        }
      },
      'statusLabel': {
        'training': '트레이닝',
        'content': '콘텐츠',
        'attendance': 'Attendance',
        'exam': '시험',
        'survey': '피드백'
      },
      'start': {
        'survey': '설문 조사 시작',
        'exam': '시험 시작'
      },
      'prefix': {
        'category': '카테고리',
        'state': '상태',
        'type': '트레이닝 유형'
      },
      'noAssigned': '배정된 트레이닝 유닛이 없습니다.',
      'noSolved': '아직 완료한 트레이닝이 없습니다. 지금 “내 트레이닝” 영역에서 첫 학습 유닛을 시작하세요!',
      'noAssignedBookable': '배정된 예약 가능 트레이닝 유닛이 없습니다.',
      'noResult': '귀하의 기준에 일치하는 트레이닝 유닛을 찾지 못했습니다.',
      'loadingFailed': '알 수 없는 오류로 인해 요청된 데이터를 불러올 수 없습니다!',
      'noResultInCategory': '해당 카테고리에 지정된 트레이닝 유닛이 없습니다.',
      'meeting': {
        'noMeeting': '일자 없음',
        'multipleMeetings': '다양한 일자'
      },
      'project': {
        'courses': '트레이닝'
      }
    },
    'login': {
      'invalidUser': 'Invalid login data. Username / Password is wrong!',
      'invalidPassword': 'Invalid login data. You have #0 more attempts to enter the password correctly.',
      'accountBlocked': 'Your account has been blocked, please contact a responsible employee.',
      'error': '사용자 이름 또는 비밀번호가 틀렸습니다!',
      'empty': '필수 필드를 모두 기입해 주십시오.',
      'back': '로그인으로 돌아가기',
      'description': '사용자 이름이나 이메일 주소를 입력하여 로그인 데이터를 받을 수 있습니다.',
      'password-reset': {
        'description': '사용자 계정에 사용할 새로운 비밀번호를 선택해 주십시오.',
        'success': '비밀번호가 성공적으로 변경되었습니다.',
        'error': '비밀번호가 일치하지 않습니다.'
      },
      'placeholder': ' 사용자 이름 / 이메일 주소',
      'button': '로그인 데이터 요청',
      'success': '요청이 성공적으로 전송되었습니다.',
      'errorForgot': '기준에 일치하는 사용자를 찾지 못했습니다!',
      'errorForgotSecurityQuestion': '입력하신 답이 틀렸습니다!'
    },
    'registration': {
      'description': '아직 계정이 없다면, 귀사의 프로모션 코드를 통해 간단히 등록하세요. 이후 즉시 이메일을 받을 수 있습니다.',
      'actionCodeError': '유효하지 않은 등록 코드',
      'actionCodeLimitExceeded': '입력한 등록 코드의 최대 등록 횟수가 초과되었습니다.',
      'registerDescription': '서식을 입력해 주십시오. 곧 이메일을 통해 로그인 데이터가 발송됩니다.',
      'title': '등록',
      'required': '필수 필드*',
      'emailError': '입력하신 이메일 주소가 이미 사용중입니다.',
      'send': '제출',
      'completeMessage': '성공적으로 등록을 완료했습니다!'
    },
    'dataProtection': {
      'accept': '동의',
      'acceptText': '네, 이용 약관에 동의합니다.'
    },
    'securityQuestion': {
      'title': '보안 질문',
      'chooseQuestion': '선택해 주십시오',
      'questionSelection': '질문을 하나만 선택하십시오.',
      'yourAnswer': '답변을 입력해 주십시오',
      'description': '보안 질문에 답변하고 사용자 계정의 새 비밀번호를 선택해 주십시오.'
    },
    'faq': {
      'title': '자주 묻는 질문'
    }
  },
  'sidebar': {
    'all': '모두',
    'categories': '카테고리',
    'status': '상태',
    'courseType': '트레이닝 종류'
  },
  'placeholder': {
    'username': '사용자 이름',
    'password': '비밀번호',
    'actionCode': '프로모션 코드'
  },
  'button': {
    'login': '회원 가입',
    'login-sso': '디렉토리 로그인 활성화',
    'send': '전송',
    'start_course': '시작',
    'changePassword': '비밀번호 변경',
    'reset': '초기화',
    'save': '저장',
    'request': '문의',
    'storno': '취소',
    'canceled': '약속 취소됨',
    'close': '닫기',
    'booking': '예약',
    'waitlist': '대기 목록',
    'start_webinar': '웨비나 시작하기',
    'open_webinar': '웨비나 열기',
    'cancel': '취소',
    'confirm': '확인하다'
  },
  'bookingState': {
    '0': '요청됨',
    '1': '예약됨',
    '2': '대기 명단',
    '3': '취소됨',
    '4': '취소됨',
    '5': '거절됨',
    '6': '날짜 저장됨',
    'onlineTraining': {
      '0': '요청됨',
      '1': '예약됨',
      '2': '거절됨'
    }
  },
  'foodPreference': {
    'none': '없음',
    'lactoseIntolerance': '유당 불내증',
    'vegetarian': '채식주의자',
    'vegan': '비건 채식주의자',
    'nutAllergy': '견과류 알러지',
    'diabetic': '당뇨병',
    'glutenFree': '글루텐 프리',
    'kosher': '코셔',
    'halal': '할랄',
    'other': '기타'
  },
  'notifications': {
    'error': '잘못되었습니다!',
    'errors': {
      'missingInput': '필드에 입력해 주십시오.',
      'imageLoad': '요청하신 이미지를 불러올 수 없습니다.'
    },
    'success': {
      'book': {
        'meeting': '클래스룸 트레이닝을 성공적으로 예약했습니다.',
        'webinar': '웨비나를 성공적으로 예약했습니다.',
        'course': '트레이닝을 성공적으로 예약했습니다.',
        'project': '프로젝트를 성공적으로 예약했습니다.,'
      },
      'request': {
        'meeting': '클래스룸 트레이닝 예약 요청을 성공적으로 전송했습니다.',
        'webinar': '웨비나 예약 요청을 성공적으로 전송했습니다.',
        'course': '트레이닝 예약 요청을 성공적으로 전송했습니다.',
        'project': '프로젝트 예약 요청을 성공적으로 전송했습니다.'
      },
      'profile': {
        'updateSuccess': '프로필을 성공적으로 업데이트했습니다!',
        'updatePasswordSuccess': '비밀번호를 성공적으로 업데이트했습니다!'
      },
      'securityQuestion': {
        'questionSave': '보안 질문을 저장했습니다!'
      }
    }
  },
  'webservice': {
    'error': {
      'duplicate': '이미 예약이 존재합니다!',
      'noaccess': '액세스가 허용되지 않음',
      'notimplementet': '해당 기능을 현재 사용할 수 없습니다.'
    },
    'success': {
      'booking': '성공적으로 예약했습니다.',
      'request': '예약 요청을 성공적으로 전송했습니다.',
      'cancel': '성공적으로 취소했습니다. ',
      'waitlist': {
        'add': '대기 목록에 있습니다.'
      },
      'reservelist': {
        'add': '목록에 있습니다.'
      }
    },
    'courseCalendar': {
      'noDate': '마감기한 없음'
    }
  },
  'trainingmanager': {
    'headline': '',
    'headlinerequest': '신청',
    'bottonrequest': '요청 제출',
    'buttonmanage': '요청 관리',
    'requestform': {
      'requester': '요청인:',
      'date': '일자:',
      'tab01': '요청 제출',
      'tab02': '그룹 / 제3자 요청',
      'tab01headline01': '참가자 이름:',
      'tab01headline02': '트레이닝 주제:',
      'tab01headline04': '참가자 이름:',
      'tab01headline03': '코스 세부 사항:',
      'tab01label01': '직함:',
      'tab01label02': '이름:',
      'tab01label03': '성:',
      'tab01label04': '님',
      'tab01label05': '님',
      'tab01label06': '카테고리:',
      'tab01label07': '직함*:',
      'tab01label08': '짧은 설명:',
      'tab01label09': '기타:',
      'tab01label09O': '기타',
      'tab01label10': '비용 센터:',
      'tab01label11': '일자 (시작/끝):*',
      'tab01label12': '코스 번호:',
      'tab01label13': '가격:',
      'tab01label14': '제공자:',
      'tab01label15': '첨부 문서:',
      'tab01label16': '파일 선택',
      'tab01label17': '선택된 파일 없음...',
      'tab01label18': '요청 제출',
      'tab01label19': '인원 추가',
      'tab01label20': '취소',
      'olheadline': '참가자 추가',
      'olheadline1': '참가자 이름',
      'olbodytext': '다음에서 다른 코스 참가자를 선택해 주십시오.',
      'olerror': '참가자를 찾을 수 없습니다. 검색 파라미터를 변경하십시오.'
    },
    'manage': {
      'headline': '요청 관리',
      'headlineInfo': '팀 요청 ',
      'bodytextInfo': '아래에서 처리 요청과 함께 현재 열려 있는 모든 트레이닝 요청을 찾을 수 있습니다.',
      'newRequest': '내 요청 제출',
      'tableHeadline01': '입구',
      'tableHeadline02': '요청인',
      'tableHeadline03': '코스 번호',
      'tableHeadline04': '직함',
      'tableHeadline05': '일자',
      'tableHeadline06': '가격',
      'tableMark': '모두 선택',
      'buttonapprove': '승인',
      'buttondecline': '거절',
      'modal': {
        'refuse': {
          'headline': '메시지 - 요청 거절',
          'bodytext': '',
          'placeholder': '텍스트를 입력해 주십시오...',
          'checkbox': '메시지 없이 거절하기',
          'button': '제출',
          'approval': '요청 승인됨',
          'refuse': '요청 거절됨'
        }
      }
    }
  }
};
